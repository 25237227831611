import { useRef } from "react";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import Modal from "react-bootstrap/Modal";
import { confirmable, createConfirmation } from "react-confirm";

const ComplexConfirmation = ({ show, proceed, dismiss, cancel, message, title }) => {
  const inputRef = useRef();

  const handleOnClick = (index) => {
    return () => {
      proceed({
        button: index,
        input: inputRef.current ? inputRef.current['value'] :  null
      });
    };
  };

  return (
    <div className="static-modal">
      <Modal animation={false} show={show} onHide={dismiss}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {message}
          <FormControl type="text" ref={inputRef} />
        </Modal.Body>
        <Modal.Footer>
          
          <Button onClick={() => proceed(false) } data-dismiss='modal' className="btn btn-secondary">Cancel</Button>
          <Button
            className="button-l"
            onClick={handleOnClick(1)}
          >
           Salvar
          </Button>
          
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export const confirm = createConfirmation(confirmable(ComplexConfirmation));
