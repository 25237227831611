import React, {useCallback, useEffect, useRef} from 'react'
import {
  GoogleMap,
  Polygon,
  OverlayView,
  Marker,
  DrawingManager,
} from '@react-google-maps/api'
import {ImovelResponse} from '../../../graphql/type'
import * as turf from '@turf/turf'
import Swal from 'sweetalert2'
import {MutationPutImovel} from '../../../graphql'
import {MutationSetLogAcessoRecurso} from '../../../graphql/services/LogAcesso'

type PropsContextMenu = {
  latLng: any[]
  onChange: any
}

export function CoordinatesToPath(geom) {
  let paths = geom.map(function (el) {
    return {lat: el[1], lng: el[0]}
  })
  return paths
}

export const MapStatic: React.FC<PropsContextMenu> = ({latLng, onChange}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [map, setMap] = React.useState(null)
  const [paths, setPaths] = React.useState([])
  const drawingManager = useRef<any>()
  const {FormImovel} = MutationPutImovel()
  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()
  // const {isLoaded} = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
  // })
  // const libraries: Libraries = ['places']
  // const { isLoaded } = useLoadScript({
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
  //   libraries: libraries
  // });
  const containerStyle = {
    height: '400px',
  }
  const center = {
    lat: -23.56834983242366,
    lng: -46.65739491734664,
  }

  if (latLng.length > 0) {
    if (latLng[0].center.type === 'Point') {
      center.lat = Number(latLng[0].center.coordinates[1])
      center.lng = Number(latLng[0].center.coordinates[0])
    } else {
      center.lat = latLng[0].center.geometry.coordinates[1]
      center.lng = latLng[0].center.geometry.coordinates[0]
    }
  }
  const options = {
    fillColor: 'lightgreen',
    fillOpacity: 0.6,
    strokeColor: 'green',
    strokeOpacity: 1,
    strokeWeight: 1,
    clickable: true,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1,
  }

  const optionsEditable = {
    fillColor: 'lightred',
    fillOpacity: 0.6,
    strokeColor: 'red',
    strokeOpacity: 1,
    strokeWeight: 1,
    clickable: true,
    draggable: true,
    editable: true,
    geodesic: false,
    zIndex: 1,
  }
  useEffect(() => {
    setPaths(latLng)
  }, [latLng])
  const onLoad = React.useCallback(
    function callback(map) {
      // This is just an example of getting and using the map instance!!! don't just blindly copy!
      // const bounds = new window.google.maps.LatLngBounds(center)
      // map.fitBounds(bounds)

      const imageMapType = new google.maps.ImageMapType({
        getTileUrl: function (coord, zoom) {
          // if (
          //   zoom < 17 ||
          //   zoom > 20
          //   // bounds[zoom][0][0] > coord.x ||
          //   // coord.x > bounds[zoom][0][1] ||
          //   // bounds[zoom][1][0] > coord.y ||
          //   // coord.y > bounds[zoom][1][1]
          // ) {
          //   return "";
          // }

          return [
            process.env.REACT_APP_MAP_TILE + '/tile/lotesfinal/',
            zoom,
            '/',
            coord.x,
            '/',
            coord.y,
            '.png?type=png',
          ].join('')
        },
        tileSize: new google.maps.Size(256, 256),
      })

      map.overlayMapTypes.push(imageMapType)
      const comercios = [
        {
          featureType: 'poi',
          stylers: [{visibility: 'off'}],
        },
      ]
      const comerciosMapType = new google.maps.StyledMapType(comercios, {name: 'Comércios'})
      map.mapTypes.set('comercios_map', comerciosMapType)

      // map.map['setMapTypeId']('comercios')

      setMap(map)
    },
    [setMap]
  )

  const polygonRef = useRef(null)
  const listenersRef = useRef([])
  const onUnmount = React.useCallback(
    function callback(map) {
      setMap(null)
    },
    [setMap]
  )
  function dbClick(e, elementMap) {
    // eslint-disable-next-line array-callback-return
    paths.map((el) => {
      if (el.id === elementMap.id) {
        el.editable = true
      } else {
        el.editable = false
      }
    })
    setPaths([...paths])
  }

  // Call setPath with new edited path
  const onEdit = useCallback(
    (el) => {
      if (polygonRef.current) {
        const nextPath = polygonRef.current
          .getPath()
          .getArray()
          .map((latLng) => {
            return {lat: latLng.lat(), lng: latLng.lng()}
          })
        // setPath(nextPath);
        onChange(nextPath)
      }
    },
    [onChange]
  )

  function disableEdit(e) {
    // eslint-disable-next-line array-callback-return
    paths.map((el) => {
      el.editable = false
    })
    setPaths([...paths])
  }

  const onLoadPoligon = useCallback(
    (polygon) => {
      console.log(polygon)
      polygonRef.current = polygon
      const path = polygon.getPath()
      listenersRef.current.push(
        path.addListener('set_at', onEdit),
        path.addListener('insert_at', onEdit),
        path.addListener('remove_at', onEdit)
      )
    },
    [onEdit]
  )

  const divStyle = {
    background: 'transparent',
  }

  function onLoadDraw(drawingManagerInstance) {
    drawingManager.current = drawingManagerInstance
  }

  const onPolygonComplete = (polygon) => {
    let GeoJSON = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [],
      },
      properties: {area: '0'},
    }

    const firstPath = polygon.getPath().getArray()[0]

    let coordenadas = []
    for (let point of polygon.getPath().getArray()) {
      coordenadas.push([point.lng(), point.lat()])
    }
    coordenadas.push([firstPath.lng(), firstPath.lat()])
    GeoJSON.geometry.coordinates.push(coordenadas)
    // const areaTotal = turf.area(GeoJSON.geometry)
    const centroid = turf.centroid(GeoJSON.geometry)
    const lng = centroid.geometry.coordinates[0]
    const lat = centroid.geometry.coordinates[1]
    // const geocoder = new google.maps.Geocoder()

    if (lat && lng) {
      // const latLng = new google.maps.LatLng(lat, lng)
      // geocoder.geocode({ location: latLng }, async function (results, status) {
      // if (status === 'OK') {
      //   let municipio = Endereco.getMunicipio()
      //   if (municipio === '') {
      //     let municipioCidade = await Endereco.getMunicipioAPI(lat, lng)
      //     municipio = municipioCidade
      //   }
      //   var place = results[0]
      // var adr_elements = formatAdressSeachGoogleMapa(place)
      Swal.fire({
        title: 'Salvar geometria?',
        confirmButtonText: 'Salvar',
        showConfirmButton: true,
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
      }).then((resultQuestion) => {
        if (resultQuestion.value === true) {
          const data = {
            id: latLng[0].id,
            marcacao: JSON.stringify(GeoJSON.geometry),
          }
          FormImovel(data).then((result) => {
            console.log(result)
            Swal.fire('', 'Geometria salva com sucesso.', 'success')
            FormSetLogAcessoRecurso({
              action: 'create',
              ferramenta: 'meus-imoveis',
              funcionalidade: 'change-geom',
              url: `/meus-imoveis/details/${latLng[0].id}#informacoes-lote`,
            })
          })

          polygon.setMap(null)
          if (drawingManager.current) {
            console.log(drawingManager)
            drawingManager.current.setDrawingMode('')
          }
        }
      })
      // }
    }

    // }
  }

  console.log(latLng)

  let isLoaded = true
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={19}
      onLoad={onLoad}
      onUnmount={onUnmount}
      key={2}
      onClick={disableEdit}
      options={{
        mapTypeId: 'comercios_map',
        mapTypeControl: false,
        mapTypeControlOptions: {
          mapTypeIds: ['comercios_map'],
        },
      }}
    >
      <DrawingManager
        onLoad={onLoadDraw}
        onPolygonComplete={onPolygonComplete}
        options={{
          drawingControl: true,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [google.maps.drawing.OverlayType.POLYGON],
          },

          polylineOptions: {
            visible: false,
          },
          markerOptions: {
            visible: false,
          },
          polygonOptions: {
            fillColor: `#20a745`,
            fillOpacity: 0.6,
            strokeWeight: 2,
            clickable: false,
            editable: true,
            zIndex: 1,
          },
          // rectangleOptions: {
          //   fillColor: `#20a745`,
          //   fillOpacity: 0.6,
          //   strokeWeight: 2,
          //   clickable: false,
          //   editable: true,
          //   zIndex: 1,
          // },
          // circleOptions: {
          //   fillColor: `#20a745`,
          //   fillOpacity: 0.6,
          //   strokeWeight: 2,
          //   clickable: false,
          //   editable: true,
          //   zIndex: 1,
          // },
        }}
      />
      {paths
        ? paths.map(function (el) {
            if (el.type === 'Point') {
              return (
                <Marker
                  position={{lat: Number(el.path[0].lat), lng: Number(el.path[0].lng)}}
                ></Marker>
              )
            } else {
              return (
                <Polygon
                  onLoad={onLoadPoligon}
                  paths={el.path}
                  key={el.id}
                  options={el.editable ? optionsEditable : options}
                  onDblClick={(e) => {
                    dbClick(e, el)
                  }}
                  // onClick={(e) => {
                  //   click(e, el)
                  // }}
                  onMouseUp={onEdit}
                  onDragEnd={onEdit}
                />
              )
            }
          })
        : ''}

      <OverlayView position={center} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
        <div style={divStyle}></div>
      </OverlayView>
      <></>
    </GoogleMap>
  ) : (
    <></>
  )
}
type Props = {
  data: ImovelResponse
  onChange: any
  latLng: any
  id: string
  ref: any
}

export const MapToPrint = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <div ref={ref} id={props.id} style={{width: '640px', height: '480px', margin: '.4rem'}}>
      <MapStatic latLng={props.latLng} onChange={props.onChange} />
    </div>
  )
})
