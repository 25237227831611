import {useMutation, useQuery} from '@apollo/client'
import {GET_EQUIPE, GET_CORRETOR_BY_ID, GET_EMPRESA_USUARIOS_EQUIPE_ADMIN} from '../query'
import {TypesDeleteEquipeResponse, TypesGetEquipeResponse, TypesSetEquipeResponse, TypesPutEquipeResponse, TypesGetEquipeById, TypesGetEquipeByCargoResponse} from '../type'
import {DELETE_CORRETOR, PUT_CORRETOR, SET_CORRETOR} from '../mutations'
import {putEquipeFormData, putEquipeFormSchema, setEquipeFormData, setEquipeFormSchema} from '../formValidations'
import {zodResolver} from '@hookform/resolvers/zod'
import {useForm} from 'react-hook-form'

interface QueryEquipeProps {
  variables: any
}

export function QueryGetEquipe({variables}: QueryEquipeProps) {
  const {data, loading, error} = useQuery<TypesGetEquipeResponse>(GET_EQUIPE, {
    variables: {...variables},
  })
  return {data, loading, error}
}

export function QueryGetEquipeByID({variables}: QueryEquipeProps) {
  const {data, loading} = useQuery<TypesGetEquipeById>(GET_CORRETOR_BY_ID, {
    variables: {...variables},
  })
  return {data, loading}
}

export function QueryGetEquipeByCargo({variables}: QueryEquipeProps) {
  const {data, loading, error} = useQuery<TypesGetEquipeByCargoResponse>(GET_EMPRESA_USUARIOS_EQUIPE_ADMIN, {
    variables: {...variables},
  })
  return {data, loading, error}
}

export function MutationDeleteEquipe() {
  const [deleteEquipe, {loading, data, error}] = useMutation<TypesDeleteEquipeResponse>(
    DELETE_CORRETOR,
    {
      refetchQueries: [GET_EQUIPE, GET_CORRETOR_BY_ID],
    }
  )

  async function HandleDeleteEquipe({variables}: QueryEquipeProps) {
    try {
      const result = await deleteEquipe({
        variables: {...variables},
        refetchQueries: [GET_EQUIPE],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return {HandleDeleteEquipe, loading, error, data}
}

export function MutationCreateEquipe() {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<setEquipeFormData>({
    resolver: zodResolver(setEquipeFormSchema),
  })

  const [setEquipeBody, {error, loading, data: DataSetEquipe}] =
    useMutation<TypesSetEquipeResponse>(SET_CORRETOR, {
      refetchQueries: [GET_EQUIPE, GET_CORRETOR_BY_ID],
    })

  async function FormEquipe(data: setEquipeFormData) {
    delete data.id
    await setEquipeBody({
      variables: {
        corretor: {...data},
      },
    })

  }

  return {register, handleSubmit, FormEquipe, loading, errors, error, DataSetEquipe}
}


export function MutationPutEquipe() {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<putEquipeFormData>({
    resolver: zodResolver(putEquipeFormSchema),
  })

  const [setEquipeBody, {error, loading, data: DataSetEquipe}] =
    useMutation<TypesPutEquipeResponse>(PUT_CORRETOR, {
      refetchQueries: [GET_EQUIPE, GET_CORRETOR_BY_ID],
    })

  async function FormEquipe(data: putEquipeFormData) {
    const id = data.id
    delete data.id
    await setEquipeBody({
      variables: {
        putEquipeId: parseInt(id),
        corretor: {...data},
      },
    })

  }


  return {register, handleSubmit, FormEquipe, loading, errors, error, DataSetEquipe}
}
