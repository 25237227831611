import { gql } from '@apollo/client'

export const GET_EMPREENDIMENTO_SITUACAO = gql`
  query GetEmpreendimentoSituacao($pagination: Pagination!) {
    GetEmpreendimentoSituacao(pagination: $pagination) {
      pageInfo {
        currentPage
        totalPages
        totalItems
        hasNextPage
        hasPreviousPage
      }
      result {
        id
        uuid
        nome
        situacao
        cor
      }
    }
  }
`

export const GET_EMPREENDIMENTO_SITUACAO_BY_ID = gql`
query GetEmpreendimentoSituacaoById($getEmpreendimentoSituacaoByIdId: Float!) {
  GetEmpreendimentoSituacaoById(id: $getEmpreendimentoSituacaoByIdId) {
    id
    uuid
    nome
    situacao
    cor
  }
}
`
