import {FC, useContext} from 'react'
import {ItemList} from './Component/ItemList'
import {Form} from 'react-bootstrap'
import {AvmContext} from './context/AvmContext'
import {BiCaretDown} from 'react-icons/bi'

type Props = {}

export const AvmPasso2: FC<Props> = () => {
  const {dummies, setDummies} = useContext(AvmContext)

  function selectAll(atual, type: string) {
    dummies.map((item) => {
      if (item.type === type) {
        item.selecionado = atual
      }
      return item
    })
    setDummies([...dummies])
  }

  function changeItem(atual, key: string) {
    dummies.map((item) => {
      if (item.key === key) {
        item.selecionado = atual
        item.naoSei = false
      }
      return item
    })
    setDummies([...dummies])
  }

  function onChangeNaoSei(atual, key: string) {
    dummies.map((item) => {
      if (item.key === key) {
        if (atual) {
          item.selecionado = false
          item.naoSei = true
        } else {
          item.naoSei = false
          item.selecionado = false
        }
      }
      return item
    })
    setDummies([...dummies])
  }

  return (
    <>
      <div className='row pb-5'>
        <h5 className='card-title'>Caracteristicas Relevantes do Imóvel:</h5>
        <span>Caso não queira informar clique em pular</span>
      </div>
      <div className='row '>
        <div className='row d-flex flex-row   all-start-between pb-5'>
          <h5 className='w-200px'>Do apartamento</h5>
          <div className='w-200px'>
            <Form.Check
              type='switch'
              id={'all-apartamento'}
              label={'  Preencher tudo com '}
              reverse
              feedbackTooltip
              onChange={(e) => {
                selectAll(e.target.checked, 'apartamento')
              }}
            />
          </div>
        </div>
      </div>
      <div className='row d-flex flex-row  '>
        <div className='col-4 mb-3'>
          <div>
            <BiCaretDown className='fs-1'></BiCaretDown> Não sei
          </div>
        </div>
        <div className='col-4 mb-3'>
          <div>
            <BiCaretDown className='fs-1'></BiCaretDown> Não sei
          </div>
        </div>
        <div className='col-4 mb-3'>
          <div>
            <BiCaretDown className='fs-1'></BiCaretDown> Não sei
          </div>
        </div>
      </div>
      <div className='row d-flex flex-row  '>
        {
          // eslint-disable-next-line array-callback-return
          dummies.map((item, index) => {
            if (item.type === 'apartamento') {
              return (
                <ItemList
                  key={index}
                  indice={item.key}
                  simNao={0}
                  nome={item.name}
                  checked={item.selecionado}
                  onChange={changeItem}
                  type={'switch'}
                  naoSei={item.naoSei}
                  onChangeNaoSei={onChangeNaoSei}
                />
              )
            }
          })
        }
      </div>

      <div className='row  d-flex all-start-between align-items-center pt-5 pb-5'>
        <h5 className='w-200px'>Do condomínio</h5>
        <div className='w-200px'>
          <Form.Check
            type='switch'
            id={'all-condominio'}
            label={'  Preencher tudo com '}
            reverse
            feedbackTooltip
            onChange={(e) => {
              selectAll(e.target.checked, 'condominio')
            }}
          />
        </div>
      </div>
      <div className='row d-flex flex-row  '>
        <div className='col-4 mb-3'>
          <div>
            <BiCaretDown className='fs-1'></BiCaretDown> Não sei
          </div>
        </div>
        <div className='col-4 mb-3'>
          <div>
            <BiCaretDown className='fs-1'></BiCaretDown> Não sei
          </div>
        </div>
        <div className='col-4 mb-3'>
          <div>
            <BiCaretDown className='fs-1'></BiCaretDown> Não sei
          </div>
        </div>
      </div>
      <div className='row d-flex flex-row'>
        {
          // eslint-disable-next-line array-callback-return
          dummies.map((item, index) => {
            if (item.type === 'condominio') {
              return (
                <ItemList
                  key={index}
                  indice={item.key}
                  simNao={0}
                  nome={item.name}
                  checked={item.selecionado}
                  onChange={changeItem}
                  onChangeNaoSei={onChangeNaoSei}
                  type={'switch'}
                  naoSei={item.naoSei}
                />
              )
            }
          })
        }
      </div>
    </>
  )
}
