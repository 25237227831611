import {Modal} from 'react-bootstrap'
import Swal from 'sweetalert2'
import {ErrorMessageComponent} from '../../../../backoffice/components/UI/ErrorMessage'
import {Loader} from '../../../../components/UI/Loader/Loader'
import {MutationSetLogAcessoRecurso} from '../../../../graphql/services/LogAcesso'
import {RgbToHex} from '../../../explorer/components/common'
import {
  MutationPutEmpreendimentoTemperatura,
  QueryGetEmpreendimentoTemperaturaByID,
} from '../../../../graphql/services/EmpreendimentoTemperatura'
import {ColorPickerChorme} from '../../../../components/UI/ColorPickerChorme'

interface Props {
  id: number
  show: boolean
  closeModal: any
}

export function EditForm({id, show, closeModal}: Props) {
  // const [color, setColor] = useState(null)

  let {data, loading: LoadingGet} = QueryGetEmpreendimentoTemperaturaByID({
    variables: {getEmpreendimentoTemperaturaByIdId: id},
  })

  const {FormEmpreendimentoTemperatura, loading, handleSubmit, register, setValue, errors} =
    MutationPutEmpreendimentoTemperatura()

  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()

  const onSubmit = async (data) => {
    // data.cor = color
    FormEmpreendimentoTemperatura(data)
      .then((result) => {
        FormSetLogAcessoRecurso({
          ferramenta: 'cadastro',
          funcionalidade: 'temperatura-terrenos',
          action: 'update',
          url: '/cadastro/temperatura-terrenos',
        })
        Swal.fire('Sucesso!', 'Dados atualizados com sucesso.', 'success')
        closeModal()
      })
      .catch((error) => {
        Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
      })
  }

  // Tratativa de loading
  if (loading || LoadingGet) {
    return <Loader text={'caregando...'}></Loader>
  }

  function onChange(color) {
    let colorChange = RgbToHex(color[0], color[1], color[2])
    setValue('cor', colorChange, {shouldValidate: true})
  }

  return (
    <>
      <Modal centered show={show} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Temperatura do Empreendimento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              className='form-control form-control-solid'
              type='number'
              hidden={true}
              defaultValue={id ? id : ''}
              {...register('id', {required: true, valueAsNumber: true})}
            />
            <div className='row'>
              <div className='col-md-12'>
                <div className='mb-6'>
                  <label htmlFor='nome' className='required form-label'>
                    Nome:
                  </label>
                  <input
                    className='form-control form-control-solid'
                    placeholder='Nome da Temperatura'
                    defaultValue={data ? data.GetEmpreendimentoTemperaturaById.nome : ''}
                    {...register('nome', {
                      pattern: /^[A-Za-z]+$/i,
                    })}
                  />
                  {errors.nome && <ErrorMessageComponent message={errors.nome?.message} />}
                </div>
              </div>

              <div className='mb-15 col-12'>
                <div className=''>
                  <label htmlFor='descricao' className='required form-label'>
                    Cor
                  </label>
                  <ColorPickerChorme
                    defaultValue={data ? data.GetEmpreendimentoTemperaturaById.cor : null}
                    onChange={onChange}
                    register={register('cor')}
                  ></ColorPickerChorme>
                  {errors.cor && <ErrorMessageComponent message={errors.cor?.message} />}
                </div>
              </div>
              <div className='col-md-12 py-6 d-flex' style={{justifyContent: 'flex-end'}}>
                <button
                  className='btn btnSecondary  pull-right '
                  type='button'
                  onClick={() => closeModal()}
                >
                  Fechar
                </button>
                <button disabled={loading} type='submit' className='btn btn-success mx-2'>
                  Salvar
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
