import { useMutation, useQuery } from '@apollo/client'
import { TypesDeleteEmpreendimentoSituacaoResponse, TypesGetEmpreendimentoSituacaoByIDResponse, TypesGetEmpreendimentoSituacaoResponse, TypesPutEmpreendimentoSituacaoResponse } from '../type/empreendimento-situacao'
import { GET_EMPREENDIMENTO_SITUACAO, GET_EMPREENDIMENTO_SITUACAO_BY_ID } from '../query/empreendimento-situacao'
import { DELETE_EMPREENDIMENTO_SITUACAO, PUT_EMPREENDIMENTO_SITUACAO, SET_EMPREENDIMENTO_SITUACAO } from '../mutations/empreendimento-situacao'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { TypesSetEmpreendimentoImovelResponse } from '../type'
import { putEmpreendimentoSituacaoFormData, putEmpreendimentoSituacaoFormSchema, setEmpreendimentoSituacaoFormData, setEmpreendimentoSituacaoFormSchema } from '../formValidations/EmpreendimentoSituacao'
import { TypesDeleteEmpreendimentoTemperaturaResponse, TypesGetEmpreendimentoTemperaturaByIDResponse, TypesGetEmpreendimentoTemperaturaResponse, TypesPutEmpreendimentoTemperaturaResponse } from '../type/empreendimento-temperatura'
import { GET_EMPREENDIMENTO_TEMPERATURA } from '../query/empreendimento-temperatura'
import { GET_EMPREENDIMENTO_TEMPERATURA_BY_ID } from '../query/empredimento-temperatura'
import { putEmpreendimentoTemperaturaFormData, putEmpreendimentoTemperaturaFormSchema, setEmpreendimentoTemperaturaFormData, setEmpreendimentoTemperaturaFormSchema } from '../formValidations/EmpreendimentoTemperatura'
import { DELETE_EMPREENDIMENTO_TEMPERATURA, PUT_EMPREENDIMENTO_TEMPERATURA, SET_EMPREENDIMENTO_TEMPERATURA } from '../mutations/empreendimento-temperatura'

interface Props {
  variables: any
}

export function QueryGetEmpreendimentoTemperatura({variables}: Props) {
  const {data, loading, error} = useQuery<TypesGetEmpreendimentoTemperaturaResponse>(GET_EMPREENDIMENTO_TEMPERATURA, {
    variables: {...variables},
  })
  return {data, loading, error}
}

export function QueryGetEmpreendimentoTemperaturaByID({variables}: Props) {
  const {data, loading, error} = useQuery<TypesGetEmpreendimentoTemperaturaByIDResponse>(GET_EMPREENDIMENTO_TEMPERATURA_BY_ID, {
    variables: {...variables},
  })
  return {data, loading, error}
}


export function MutationDeleteEmpreendimentoTemperatura() {
  const [deleteEmpreendimentoTemperatura, {loading, data, error}] = useMutation<TypesDeleteEmpreendimentoTemperaturaResponse>(
    DELETE_EMPREENDIMENTO_TEMPERATURA,
    {
      refetchQueries: [GET_EMPREENDIMENTO_TEMPERATURA],
    }
  )

  async function HandleDeleteEmpreendimentoTemperatura({variables}: Props) {
    try {
      const result = await deleteEmpreendimentoTemperatura({
        variables: {...variables},
        refetchQueries: [GET_EMPREENDIMENTO_TEMPERATURA],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return {HandleDeleteEmpreendimentoTemperatura, loading, error, data}
}

export function MutationSetEmpreendimentoTemperatura() {
  const {
    register,
    handleSubmit,
    formState: {errors},
    setValue
  } = useForm<setEmpreendimentoTemperaturaFormData>({
    resolver: zodResolver(setEmpreendimentoTemperaturaFormSchema),
  })
  
  const [setEmpreendimentoTemperaturaBody, {error, loading, data: DataSetEquipe}] =
    useMutation<TypesSetEmpreendimentoImovelResponse>(SET_EMPREENDIMENTO_TEMPERATURA, {
      refetchQueries: [GET_EMPREENDIMENTO_TEMPERATURA],
    })

  async function FormEmpreendimentoTemperatura(data: setEmpreendimentoTemperaturaFormData) {
    await setEmpreendimentoTemperaturaBody({
      variables: {
        temperatura: {
          nome: data.nome,
          cor: data.cor
        },
      },
    })

  }

  return {register, setValue, handleSubmit, FormEmpreendimentoTemperatura, loading, errors, error, DataSetEquipe}
}


export function MutationPutEmpreendimentoTemperatura() {
  const {
    register,
    handleSubmit,
    formState: {errors},
    setValue,
    getValues
  } = useForm<putEmpreendimentoTemperaturaFormData>({
    resolver: zodResolver(putEmpreendimentoTemperaturaFormSchema),
  })

  const [putEmpreendimentoTemperaturaBody, {error, loading, data: DataSetEquipe}] =
    useMutation<TypesPutEmpreendimentoTemperaturaResponse>(PUT_EMPREENDIMENTO_TEMPERATURA, {
      refetchQueries: [GET_EMPREENDIMENTO_TEMPERATURA],
    })

  async function FormEmpreendimentoTemperatura(data: putEmpreendimentoTemperaturaFormData) {
    await putEmpreendimentoTemperaturaBody({
      variables: {
        temperatura: {
          id: data.id,
          nome: data.nome,
          cor: data.cor
        },
        putEmpreendimentoTemperaturaId: data.id
      },
    })

  }


  return {setValue, register, handleSubmit, FormEmpreendimentoTemperatura, loading, errors, error, DataSetEquipe,getValues}
}
