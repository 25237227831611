import { gql } from "@apollo/client";

export const GET_EQUIPES = gql`
query GetEquipe($pagination: Pagination) {
  GetEquipe(pagination: $pagination) {
    result {
      id
      nome
      situacao
      data_cadastro
      data_alteraco
      id_empresa
      id_usuario_cadastro
      id_usuario_alteracao
      id_usuario_exclusao
      data_exclusao
    }
    pageInfo {
      currentPage
      totalPages
      totalItems
      hasNextPage
      hasPreviousPage
    }
  }
}
`

export const GET_EQUIPE_BY_ID = gql`
query GetEquipeById($getEquipeByIdId: Float!) {
  GetEquipeById(id: $getEquipeByIdId) {
    id
    nome
    situacao
    data_cadastro
    data_alteraco
    id_empresa
    id_usuario_cadastro
    id_usuario_alteracao
    id_usuario_exclusao
    data_exclusao
  }
}
`
