import {useContext, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {ItensImoveisProps, Tipologia} from '../components/Types'
import {BiAddToQueue, BiLinkExternal} from 'react-icons/bi'
import {DataContext} from '../context/DataContext'
import {formatDate, formatNumber} from '../../explorer/components/common'
import secureLocalStorage from 'react-secure-storage'
import * as XLSX from 'xlsx'

export function ModalDados() {
  const [show, setShow] = useState(false)
  const {data} = useContext(DataContext)

  let usuarioOrulo = secureLocalStorage.getItem('usuario_orulo')

  function closeModal() {
    setShow(false)
  }

  function openModal() {
    setShow(true)
  }

  function exportar(data) {
    const dataToExport = []
    // eslint-disable-next-line array-callback-return
    data.map((item: ItensImoveisProps) => {
      // eslint-disable-next-line array-callback-return
      item.tipologias.map((itemTipologia: Tipologia) => {
        
        

        if (itemTipologia.computed) {
          dataToExport.push({
            'Situação': item.situacao,
            'Nome': item.nome,
            'Endereço': item.endereco,
            'Nome Incorporador': item.nome_incorporador,
            'Data Lançamento': formatDate(item.data_lancamento),
            'Área Privativa': itemTipologia.private_area ? formatNumber(itemTipologia.private_area, 'decimal') : 0 ,
            'Vagas': itemTipologia.parking,
            'Suítes': itemTipologia.suites,
            'Dormitórios': itemTipologia.bedrooms,
            'Tipo': itemTipologia.type,
            'Preço Inicial':itemTipologia.preco_lancamento ? formatNumber( itemTipologia.preco_lancamento,'currency') : '0',
            'Preço Inicial m²':itemTipologia.preco_lancamento ? formatNumber( itemTipologia.preco_lancamento/itemTipologia.private_area,'currency') : 0,
            'Preço Estoque': formatNumber(itemTipologia.original_price ,'currency'),
            'Preço Estoque m²': itemTipologia.private_area > 0 ? formatNumber(itemTipologia.original_price / itemTipologia.private_area,'currency') : 0,
            'Total Unidades': itemTipologia.total_units,
            'Estoque': itemTipologia.stock,
            'Vendas': itemTipologia.total_units - itemTipologia.stock,
            '% Vendas': itemTipologia.vendas_perc_tip,
            'Data Preço': formatDate(item.data_preco),
            'Site': item.webpage,
          })
        }
      })
    })

    const worksheet = XLSX.utils.json_to_sheet(dataToExport)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, worksheet, 'Planilha de Dados')
    XLSX.writeFile(wb, `Inteligência de Mercado.xlsx`)
  }

  return (
    <>
      <button
        onClick={openModal}
        className='btn btn-light-primary btn-sm d-block me-2 w-100'
        style={{width: '100% !important'}}
      >
        <BiAddToQueue className='fs-2 me-1' />
        Tabela de Dados
      </button>
      <Modal centered animation show={show} onHide={() => closeModal()} fullscreen={true}>
        <Modal.Header closeButton className='d-flex'>
          <Modal.Title>Tabela de empreendimentos</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-xl' style={{width: '100%'}}>
          <div className='row mb-5'>
            <div className='col-md-12'>
              {usuarioOrulo ? (
                <button
                  className='btn btn-primary pull-right'
                  style={{margin: '0 auto'}}
                  onClick={() => exportar(data)}
                >
                  Exportar dados
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <table className='table  gs-5 '>
                <thead>
                  <tr>
                    <th>
                      <input type='checkbox' />
                    </th>
                    <th>Situação</th>
                    <th>Nome</th>
                    <th>Endereço</th>
                    <th>Incorporadora</th>
                    <th  className='text-center'>Data Lançamento</th>
                    <th  className='text-center'>Área Privativa</th>
                    <th  className='text-center'>Vagas</th>
                    <th  className='text-center'>Suítes</th>
                    <th  className='text-center'>Dormitórios</th>
                    <th >Tipo</th>
                    <th  className='text-center' >Preço Inicial</th>
                    <th  className='text-center' >Preço Inicial m²</th>
                    <th  className='text-center'>Preço Estoque </th>
                    <th  className='text-center'>Preço Estoque m²</th>
                    <th  className='text-center'>Total Unidades</th>
                    <th  className='text-center '>Estoque</th>
                    <th  className='text-center'>Vendas</th>
                    <th  className='text-center'>% Vendas </th>
                    <th  className='text-center'>Data Preço</th>
                    <th  className='text-center'>Site</th>
                  </tr>
                </thead>
                <tbody>
                  {data ? (
                    data.map((item: ItensImoveisProps) => {
                      return item.tipologias.map((itemTipologia: Tipologia) => {
                        let background =
                          item.removed || itemTipologia.computed === false ? '#ffc3c3' : 'white'

                        return (
                          <tr style={{backgroundColor: background, border: 1, borderColor: '#333'}}>
                            <td>
                              <input type='checkbox' />
                            </td>
                            <td>{item.situacao}</td>
                            <td>{item.nome}</td>
                            <td>{item.endereco}</td>
                            <td>{item.nome_incorporador}</td>
                            <td  className='text-center'>{formatDate(item.data_lancamento)}</td>
                            <td className='text-center'>
                              {formatNumber(itemTipologia.private_area, 'decimal')}
                            </td>
                            <td className='text-center'>{itemTipologia.parking}</td>
                            <td className='text-center'>{itemTipologia.suites}</td>
                            <td className='text-center'>{itemTipologia.bedrooms}</td>
                            <td>{itemTipologia.type}</td>

                            
                            <td className='text-right'>
                              {formatNumber(
                                 itemTipologia.preco_lancamento,
                                'currency'
                              )}
                            </td>
                            <td className='text-right'>
                              {formatNumber(
                                itemTipologia.preco_lancamento/itemTipologia.private_area,
                                'currency'
                              )}
                            </td>
                            
                            <td className='text-right'>
                              {formatNumber(
                                itemTipologia.original_price ,
                                'currency'
                              )}
                            </td>
                            
                            <td className='text-right'>
                              {formatNumber(
                                itemTipologia.original_price / itemTipologia.private_area,
                                'currency'
                              )}
                            </td>
                            
                            
                           
                    
                            <td  className='text-center'>{itemTipologia.total_units}</td>
                            <td className='text-center'>{itemTipologia.stock}</td>
                            <td  className='text-center'>{itemTipologia.total_units - itemTipologia.stock}</td>
                            <td  className='text-center'>{itemTipologia.vendas_perc_tip}</td>
                            <td  className='text-center'>{formatDate(item.data_preco)}</td>
                            <td  className='text-center'>
                              <a href={item.webpage} target='_blank' rel='noreferrer'>
                                <BiLinkExternal></BiLinkExternal>
                              </a>
                            </td>
                          </tr>
                        )
                      })
                    })
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
