import {useEffect, useState} from 'react'
import {Loader} from '../Loader/Loader'
import {QueryGetEmpreendimentoTemperatura} from '../../../graphql/services/EmpreendimentoTemperatura'
import {FormLabel} from 'react-bootstrap'

export function TemperatureBar({
  SetValueSituacao,
  valueSituacao,
  showLabel = true,
  className = '',
  disabled=false
}) {
  const [selectedItem, setSelectedItem] = useState(valueSituacao || '')

  useEffect(() => {
    setSelectedItem(valueSituacao)
  }, [setSelectedItem, valueSituacao])

  const {data, loading, error} = QueryGetEmpreendimentoTemperatura({
    variables: {
      pagination: {
        pagina: 0,
        quantidade: 100,
      },
    },
  })

  // Tratativa de loading
  if (loading) {
    return <Loader text={'carregando...'} />
  }

  if (error) {
    return <div>Erro ao carregar </div>
  }

  return (
    <>{
      data?.GetEmpreendimentoTemperatura.result.length > 0 ?
    <>
      {showLabel ? <FormLabel className='form-label align-itens'>Temperatura</FormLabel> : <></>}
      <div className={`temperatura ${className}`} style={{display: 'flex'}}>
        {data?.GetEmpreendimentoTemperatura.result.map((item, index) => (
          <div
            key={index}
            className={selectedItem === item.nome ? 'active' : 'none'}
            style={{
              // width: '42px',
              // height: '32px',
              backgroundColor: item.cor,
              borderRadius:
                index === 0
                  ? '8px 0 0 8px'
                  : index === data.GetEmpreendimentoTemperatura.result.length - 1
                  ? '0 8px 8px 0'
                  : '0',
              // color: 'white',
              // display: 'flex',
              // alignItems: 'center',
              // alignContent:'center',
              // justifyContent: 'center',
              // cursor: 'pointer',
              // fontSize: '14px',
              // transition: 'all 0.3s ease', // Aumenta a transição para suavizar o efeito
              // border: , // Borda azul quando selecionado
            }}
            onClick={() => {
              if (!disabled){
                setSelectedItem(item.nome)
                SetValueSituacao(Number(item.id))
              }
            }}
            title={item.nome} // Exibe o nome ao passar o mouse
          >
            <span className='bold'>{item.nome}</span>
          </div>
        ))}
        <input type='hidden' value={selectedItem || ''} name='id_temperatura'></input>
      </div>
      </>
:<></>}
    </>
  )
}
