import { z } from "zod"

// Esquema para criar ou ajustar a situação de um empreendimento
export const setEmpreendimentoTemperaturaFormSchema = z.object({
  nome: z.string().nonempty('O nome da temperatura é obrigatório!'),
  cor: z.string().nullable(),
});

export type setEmpreendimentoTemperaturaFormData = z.infer<typeof setEmpreendimentoTemperaturaFormSchema>;

// Esquema para atualizar a temperatura de um empreendimento
export const putEmpreendimentoTemperaturaFormSchema = z.object({
  id: z.number(),
  nome: z.string().nonempty('O nome da temperatura é obrigatório!'),
  cor: z.string().nullable()
});

export type putEmpreendimentoTemperaturaFormData = z.infer<typeof putEmpreendimentoTemperaturaFormSchema>;
