import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { useState } from 'react';
import { MutationSetLogAcessoRecurso } from '../../../../graphql/services/LogAcesso';
import { MutationSetEmpreendimentoTemperatura } from '../../../../graphql/services/EmpreendimentoTemperatura';
import { Loader } from '../../../../components/UI/Loader/Loader';
import { ErrorMessageComponent } from '../../../../backoffice/components/UI/ErrorMessage';
import { ColorPicker } from './ColorPicker';
import { RgbToHex } from '../../../explorer/components/common';
import { setEmpreendimentoTemperaturaFormData } from '../../../../graphql/formValidations/EmpreendimentoTemperatura';

interface Props {
  closeModal: () => void;
  show: boolean;
}

export function CreateForm({ closeModal, show }: Props) {
  const [color, setColor] = useState<string>(''); // Inicializado como string vazia

  const {
    FormEmpreendimentoTemperatura,
    loading,
    handleSubmit,
    register,
    setValue,
    errors,
  } = MutationSetEmpreendimentoTemperatura();

  const { FormSetLogAcessoRecurso } = MutationSetLogAcessoRecurso();

  const onSubmit = async (data: setEmpreendimentoTemperaturaFormData) => {
    if (!/^#[0-9A-Fa-f]{6}$/.test(data.cor)) {
      Swal.fire('Erro!', 'A cor selecionada é inválida!', 'error');
      return;
    }

    
    try {
      await FormEmpreendimentoTemperatura(data);

      // Log de acesso
      await FormSetLogAcessoRecurso({
        ferramenta: 'cadastro',
        funcionalidade: 'termperatura-empreendimentos',
        action: 'create',
        url: '/cadastro/termperatura-empreendimentos',
      });

      Swal.fire('Sucesso!', 'Temperatura criada com sucesso.', 'success');
      closeModal();
    } catch (err: any) {
      console.error('Erro ao criar situação:', err); // Debug do erro
      Swal.fire('Erro!', err.message || 'Ocorreu um erro durante o processo.', 'error');
    }
  };

  const onChange = (color: number[]) => {

    const hexColor = RgbToHex(color[0], color[1], color[2]);
    setColor(hexColor);
    setValue('cor', hexColor, { shouldValidate: true }); // Atualizando o campo 'cor'
  };

  if (loading) {
    return <Loader text="Carregando..." />;
  }

  return (
    <Modal centered animation show={show} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Nova Temperatura do Empreendimento</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-12">
              <div className="mb-6">
                <label htmlFor="nome" className="required form-label">
                  Nome:
                </label>
                <input
                  className="form-control form-control-solid"
                  placeholder="Nome da Temperatura"
                  defaultValue=""
                  {...register('nome', {
                    required: 'O nome é obrigatório!',
                    pattern: {
                      value: /^[A-Za-z\s]+$/i,
                      message: 'Insira um nome válido!',
                    },
                  })}
                />
                {errors.nome && (
                  <ErrorMessageComponent message={errors.nome.message} />
                )}
              </div>
            </div>
            <div className="mb-15 col-12">
              <div className="">
                <label htmlFor="cor" className="required form-label">
                  Cor
                </label>
                <ColorPicker hex={color} onChange={onChange} />
                {errors.cor && (
                  <ErrorMessageComponent message={errors.cor.message} />
                )}
              </div>
            </div>
            <div
              className="col-md-12 py-6 d-flex"
              style={{ justifyContent: 'flex-end' }}
            >
              <button
                className="btn btn-secondary pull-right"
                type="button"
                onClick={closeModal}
              >
                Fechar
              </button>
              <button
                disabled={loading || !color}
                type="submit"
                className="btn btn-success mx-2"
              >
                Salvar
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}


