import { z } from "zod"

export const setEmpreendimentoImovelCorretorFormSchema = z.object({
    id_empreendimento: z.number().min(1, { message: 'Campo Obrigatório.'}),
    id_corretor: z.number().optional(),
    type: z.string().optional(),
    corretor:z.object({
        nome: z.string().trim().optional(),
        telefone: z.string().trim().optional(),
        email: z.string().trim().optional(),
        empresa: z.string().optional(),
    }).optional()
    }).refine((data)=>{
        if (data.type === 'existente') {
          if (data.id_empreendimento && data.id_corretor){
            return true;
          }else{
            return false
          }
        }else{
          return true;
        }
    },
    {
      message: "Informe o corretor.",
      path: ["id_corretor"], // Define onde o erro será exibido
    }).refine(
      (data) => {
        if (data.type === 'novo') {
          const { nome, telefone, email } = data.corretor;
          const algumPreenchido = (nome || telefone || email) ? true : false;

            if (algumPreenchido){
              if (nome.trim().length > 0 && telefone.trim().length >= 8 &&  email.trim().length > 0 ){
                return true
              }else{
                return false
              }
            }else{
              return false;//!algumPreenchido || todosValidos;
            }
          }else{
            return true
          }
            
      },
      {
        message: "Campos nome, email e telefone são obrigatórios.",
        path: ["corretor"], // Define onde o erro será exibido
      }
    )
export type setEmpreendimentoImovelCorretorFormData = z.infer<typeof setEmpreendimentoImovelCorretorFormSchema>
