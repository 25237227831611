import { Buttons } from './partials/Buttons'
import { BiPlus } from 'react-icons/bi'
import { useState } from 'react'
import { EditForm } from './partials/EditForm'
import { CreateForm } from './partials/CreateForm'
import { CardTables } from '../../../components/UI/CardTables'
import PaginationComponent from '../../../components/UI/PaginationComponents'
import LoadingTable from '../../../components/UI/CardTables/LoadingTable'
import ErrorTable from '../../../components/UI/CardTables/ErrorTable'
import { QueryGetEquipes } from '../../../graphql/services/Equipes'

export function Table() {
  const [pagiancao, setPaginacao] = useState({ pagina: 0, quantidade: 10 })
  const [showCreate, setShowCreate] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [id, setId] = useState(null)

  const { data, loading, error } = QueryGetEquipes({
    variables: {
      pagination: {
        pagina: pagiancao.pagina,
        quantidade: pagiancao.quantidade,
      },
    },
  })

  function CloseModalCreate() {
    setShowCreate(false)
    setId(null)
  }

  function OpenModalCreate(id) {
    setShowCreate(true)
    setId(id)
  }
  function CloseModalEdit() {
    setShowEdit(false)
    setId(null)
  }

  function OpenModalEdit(id) {
    setShowEdit(true)
    setId(id)
  }


  return (
    <>
      <CardTables
        headerTitle={'Equipes'}
        headerSubtTitle={`Até o momento ${data ? data.GetEquipe.pageInfo.totalItems : 0
          } equipes registrados.`}
        headerToolbar={
          <button
            className='btn btn-sm btn-light-primary'
            onClick={(e) => {
              e.preventDefault()
              OpenModalCreate(null)
            }}
          >
            <BiPlus className='fs-3'></BiPlus>
            Nova Equipe
          </button>
        }
        footer={
          data ? (
            <PaginationComponent
              pagesInfo={data.GetEquipe.pageInfo}
              setPagesInfo={(pagina: number, quantidade: number) => {
                setPaginacao({ pagina: pagina, quantidade: quantidade })
              }}
            ></PaginationComponent>
          ) : (
            <></>
          )
        }
      >
        <div className='table-responsive  '>
          <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th className='min-w-50px'>Id</th>
                <th className='min-w-150px'>Nome</th>
                <th className='min-w-50px text-center'>Situação</th>
                <th className='min-w-100px text-end'>Ação</th>
              </tr>
            </thead>
            <tbody>
              <LoadingTable loading={loading} />
              <ErrorTable error={error} />
              {data ? (
                data.GetEquipe.result.length > 0 ? (
                  data.GetEquipe.result.map((response) => {
                    return (
                      <tr key={response.id}>
                        <td>
                          <div className='d-flex align-items-center'>{response.id}</div>
                        </td>
                        <td>{response.nome}</td>
                        <td className='text-center'>
                          {response.situacao === 1 ? (
                            <span className='badge badge-light-primary fw-bold me-auto px-4 py-3'>
                              Ativo
                            </span>
                          ) : (
                            <span className='badge badge-light-danger fw-bold me-auto px-4 py-3'>
                              Inativo
                            </span>
                          )}
                        </td>
                        <td>
                          <Buttons id={response.id} setIdEdit={OpenModalEdit} />
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={10} className='text-center'>
                      Nenhum registro encontrado
                    </td>
                  </tr>
                )
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </CardTables>
      {showEdit ? <EditForm id={id} show={showEdit} closeModal={CloseModalEdit}></EditForm> : <></>}
      {showCreate ? (
        <CreateForm closeModal={CloseModalCreate} show={showCreate}></CreateForm>
      ) : (
        <></>
      )}
    </>
  )
}
