import {InputHTMLAttributes, useEffect, useState} from 'react'
import {ErrorMessageComponent} from '../../../backoffice/components/UI/ErrorMessage'
import {Form, InputGroup} from 'react-bootstrap'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  register?: any
  error?: any
  control: any
  onValueChange: any
  value: number
  placeholder: string
  defaultValue: number
}

export function FormInputTouchSpinFracao({
  label,
  register,
  error,
  control,
  onValueChange,
  defaultValue,
  placeholder,
  ...props
}: InputProps) {
  const [value, setValue] = useState<number>(0)

  useEffect(() => {
    // let valor = (Number(defaultValue) *100).toLocaleString('pt-br',{style: 'currency', currency: 'BRL', minimumFractionDigits: 0,maximumFractionDigits: 0})
    setValue(defaultValue)
    onValueChange(defaultValue)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue])

  useEffect(() => {
    onValueChange(value)
  }, [onValueChange, value])


  return (
    <div className='form-group row'>
      <label className='col-form-label'>{label}</label>
      <div className=''>
        <div className='input-group mb-3'>
          {/* <Controller
            control={control}
            name={name}
                
                   
                   
            render={({field}) => {
              return ( */}
          <InputGroup className='mb-3 all-center'>
            <InputGroup.Text
              onClick={() => {
                if (value === 0) {
                  setValue(0)
                } else {
                  setValue(value - 0.5)
                }
              }}
            >
              -
            </InputGroup.Text>
            <Form.Control
              placeholder={placeholder}
              defaultValue={value}
              value={value}
              onChange={(e) => {
                setValue(Number(e.target.value))
              }}
            />
            <InputGroup.Text
              onClick={() => {
                setValue(value + 0.5)
              }}
            >
              +
            </InputGroup.Text>
          </InputGroup>
          {/* ) */}
          {/* }}
            {...props}
          /> */}
          {error && <ErrorMessageComponent message={error} />}
        </div>
      </div>
    </div>
  )
}
