import {useEffect, useState} from 'react'
import {useLocation} from 'react-router'
import '../global.css'
import {DrawerComponent} from '../../../_metronic/assets/ts/components'
import {Footer} from '../../../_metronic/layout/components/Footer'
import {TableLancamento} from './partials/Table'
import {Statics} from './partials/Statics'
import {Infos} from './partials/Infos'
import {Files} from './partials/Files'
import {APiUrbit} from '../../services/api/apiUrbit'
import {Property} from './partials/types'
import {useParams} from 'react-router-dom'
import {Loader} from '../../components/UI/Loader/Loader'
import {Chart1} from './partials/Chart1'
import {Chart2} from './partials/Chart2'
import {Chart3} from './partials/Chart3'
import {Chart4} from './partials/Chart4'

export function LancamentoPage() {
  const location = useLocation()
  const [result, setResult] = useState<Property>(null)
  const {id} = useParams<{id: string}>()

  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])
  
  useEffect(() => {
    async function fetchData() {
      try {
        const api = new APiUrbit()
        const response: Property = await api.get('real-estate-launches/' + id)
        setResult(response)
      } catch (error) {
        console.error('Erro ao buscar os dados:', error)
      }
    }
    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!result) {
    return <Loader text={'Carregando..'}></Loader>
  }
  if (Array.isArray(result) && result.length === 0 ) {
    return <div className="text-center">Lançamento sem informações </div>
  }
  
  return (
    <>
      {/* <HeaderWrapperFluid /> */}
      {/* <Empreendimentos /> */}
      <div className='max-w-1200px py-10'>
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
          <div className='post d-flex flex-column-fluid' id='kt_post'>
            {/* <HeaderPage /> */}
            <div id='kt_content_container' className='container-fluid'>
              <div className=''>
                <div className='alert alert-primary'>
                  <p>
                    <b>Observações</b>
                  </p>
                  <hr />
                  <p>
                    <b> 1. </b> Informações provenientes da empresa <b>ÓRULO</b>.
                  </p>
                  <p>
                    <b> 2. </b> Empreendimentos lançados <b>antes de 2022</b> podem <b>não</b>{' '}
                    conter dados históricos e estatísticos
                  </p>
                </div>
              </div>
              <div className='kt_content_container  d-flex flex-column flex-column-fluid'>
                <div className='card card-custom'>
                  <div className='card-header all-start'>
                    <h1 className='fts-2 pt-5'>{result ? result[0].name : 'Carregando...'} </h1>
                  </div>
                  {/* begin::Body */}
                  <div className='card-body'>
                    <Infos result={result} features={result ? result[0].features : '0'} />
                    <div className='row'>
                      <div className='col-12'>
                        <Chart1 id={id}></Chart1>
                      </div>
                      <div className='col-12'>
                        <Chart2 id={id}></Chart2>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-12'>
                        <Chart3 id={id}></Chart3>
                      </div>
                      <div className='col-12'>
                        <Chart4 id={id}></Chart4>
                      </div>
                    </div>

                    <Statics />

                    <ul className='nav nav-pills mb-3' id='pills-tab' role='tablist'>
                      <li className='nav-item' role='presentation'>
                        <button
                          className='nav-link bg-active-success text-active-white text-black active'
                          id='pills-home-tab'
                          data-bs-toggle='pill'
                          data-bs-target='#pills-home'
                          type='button'
                          role='tab'
                          aria-controls='pills-home'
                          aria-selected='true'
                        >
                          Tipologias
                        </button>
                      </li>
                      <li className='nav-item' role='presentation'>
                        <button
                          className='nav-link bg-active-success text-active-white text-black'
                          id='pills-profile-tab'
                          data-bs-toggle='pill'
                          data-bs-target='#pills-profile'
                          type='button'
                          role='tab'
                          aria-controls='pills-profile'
                          aria-selected='false'
                        >
                          Localização
                        </button>
                      </li>
                    </ul>
                    <div className='tab-content' id='pills-tabContent'>
                      <div
                        className='tab-pane fade show active'
                        id='pills-home'
                        role='tabpanel'
                        aria-labelledby='pills-home-tab'
                      >
                        <TableLancamento
                          tipologies={result ? result[0].typologies?  JSON.parse(result[0].typologies) : null : null}
                          className='mb-5 mb-xl-8'
                        />
                      </div>
                      <div
                        className='tab-pane fade'
                        id='pills-profile'
                        role='tabpanel'
                        aria-labelledby='pills-profile-tab'
                      >
                        {/* <MapView latLng={latlng} showLotes={false} showMarker={true}></MapView> */}
                      </div>
                    </div>
                    <Files
                      images={result ? result[0].images : null}
                      files={result ? result[0].files : null}
                    />
                  </div>
                  {/* end::Body */}

                  {/* begin::Footer */}
                  {/* <div className='card-footer py-6'>
                    <div className='row'>
                      <div className='col-lg-3'></div>
                      <div className='col-lg-9'>


                        <button
                          type='button'
                          id='kt_layout_builder_reset'
                          className='btn btn-active-light btn-color-muted'
                        >
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div> */}
                  {/* end::Form */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}
