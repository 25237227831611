import {useMutation, useQuery} from '@apollo/client'
import {GET_CORRETORES, GET_EMPREENDIMENTO_CORRETORES} from '../query'
import {TypesGetEmpreendimentoCorretoresResponse} from '../type/empreendiemnto_corretor'
import {useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {GET_IMOVEL_CORRETORES} from '../query/imovel-corretores'
import {SET_EMPREENDIMENTO_IMOVEL_CORRETOR} from '../mutations'
import {
  setEmpreendimentoImovelCorretorFormData,
  setEmpreendimentoImovelCorretorFormSchema,
} from '../formValidations/EmpreendimentoImovelCorretor'
import {
  TypesSetEmpreendimentoImovelCorretorResponse
} from '../type/imovel-corretores'

interface Props {
  variables: any
}

export function QueryGetEmpreendimentoCorretores({variables}: Props) {
  const {data} = useQuery<TypesGetEmpreendimentoCorretoresResponse>(GET_EMPREENDIMENTO_CORRETORES, {
    variables: {...variables},
  })
  return data
}

export function MutationSetEmpreendimentoImovelCorretores() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
    reset,
  } = useForm<setEmpreendimentoImovelCorretorFormData>({
    resolver: zodResolver(setEmpreendimentoImovelCorretorFormSchema),
  })

  const [setCorretorBody, {error, loading, data: DataSetImovelCorretor}] =
    useMutation<TypesSetEmpreendimentoImovelCorretorResponse>(SET_EMPREENDIMENTO_IMOVEL_CORRETOR, {
      refetchQueries: [GET_IMOVEL_CORRETORES, GET_EMPREENDIMENTO_CORRETORES, GET_CORRETORES],
    })

  async function FormEmpreendimentoImovelCorretor(data: setEmpreendimentoImovelCorretorFormData) {
    if (data.corretor.nome === '') {
      delete data.corretor
    }
    let {id_empreendimento, id_corretor, corretor} = data

    return await setCorretorBody({
      variables: {
        idEmpreendimento: id_empreendimento,
        idCorretor: id_corretor,
        corretor: corretor,
      },
      refetchQueries: [GET_IMOVEL_CORRETORES, GET_EMPREENDIMENTO_CORRETORES, GET_CORRETORES],
    })
  }

  return {
    register,
    handleSubmit,
    setValue,
    FormEmpreendimentoImovelCorretor,
    loading,
    errors,
    error,
    DataSetImovelCorretor,
    reset,
  }
}
