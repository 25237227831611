import { FC, useEffect, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import './main.css'
import { MenuSecondary } from './MenuSecondary'
import {
  BiBuildings,
  BiCog,
  BiCompass,
  BiHome,
  BiLogOut,
  BiMapPin,
  BiWrench,
  BiFile,
  BiBarChartSquare,
  BiBarChart,
  BiPurchaseTagAlt
} from 'react-icons/bi'

import {
  FiHelpCircle
} from 'react-icons/fi'
import secureLocalStorage from 'react-secure-storage'
import clsx from 'clsx'
import { useLayout } from '../../core'
import { KTSVG } from '../../../helpers'
import { toAbsoluteUrl } from '../../../helpers/Utils'
import { AsideMenu } from './Mobile/AsideMenu'
import { AiOutlineUserSwitch } from 'react-icons/ai'
import { MutationSetLogAcessoRecurso } from '../../../../app/graphql/services/LogAcesso'

const AsideDefault: FC = () => {
  const asideRef = useRef<HTMLDivElement | null>(null)
  const asideFooter = useRef<HTMLDivElement | null>(null)
  const asideMenu = useRef<HTMLDivElement | null>(null)
  let history = useHistory()
  const { config, classes } = useLayout()
  const { aside } = config
  const { FormSetLogAcessoRecurso } = MutationSetLogAcessoRecurso()

  const minimize = () => {
    asideRef.current?.classList.add('animating')
    setTimeout(() => {
      asideRef.current?.classList.remove('animating')
    }, 300)
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth < 990);

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth < 990);
    // document.get  window.innerWidth
    asideRef.current.style.height = window.innerHeight + 'px'
    if (asideFooter.current) {
      // asideFooter.current.style.height = 200 + 'px'
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  function handleDashboard() {
    history.push('/')
  }

  function handleExplorer() {
    history.push('/explorer')
    FormSetLogAcessoRecurso({ ferramenta: 'explorer', funcionalidade: 'inicio', action: 'list', url: '/explorer' });

  }

  function handlePessoas() {
    history.push('/pessoas')
    FormSetLogAcessoRecurso({ ferramenta: 'pessoas', funcionalidade: 'listagem', action: 'list', url: '/pessoas' });
  }

  function handleAvaliacoes() {
    history.push('/avaliacoes')
    FormSetLogAcessoRecurso({ ferramenta: 'pessoas', funcionalidade: 'listagem', action: 'list', url: '/pessoas' });
  }


  function handleMeusLocais() {
    history.push('/meus-imoveis')
    FormSetLogAcessoRecurso({ ferramenta: 'meus-imoveis', funcionalidade: 'listagem', action: 'list', url: '/meus-imoveis' });

  }

  function handleEmpreendimentos() {
    history.push('/empreendimentos')
    FormSetLogAcessoRecurso({ ferramenta: 'empreendimentos', funcionalidade: 'listagem', action: 'list', url: '/empreendimentos' });
  }

  function handleAnaliseMercado() {
    history.push('/analise-mercado')
    FormSetLogAcessoRecurso({ ferramenta: 'analise-mercado', funcionalidade: 'listagem', action: 'list', url: '/analise-mercado' });
  }
  function handleInteligenciaMercado() {
    history.push('/inteligencia-mercado')
    FormSetLogAcessoRecurso({ ferramenta: 'inteligencia-mercado', funcionalidade: 'listagem', action: 'list', url: '/inteligencia-mercado' });
  }

  function activetab(tabId, showHide) {
    // Seleciona todas as abas dentro de '.tab-content'
    const allTabs = document.querySelectorAll('#AsideSecondary .tab-content .tab-pane');
  
    // Itera sobre todas as abas para desativar
    allTabs.forEach((tab) => {
      tab.classList.remove('show', 'fade'); // Remove as classes de ativação
      tab.classList.add('hide');           // Adiciona a classe de desativação
    });
  
    // Ativa a aba específica passada por tabId
    const targetTab = document.getElementById(tabId);
    if (targetTab && showHide === 'show') {
      targetTab.classList.add('show'); // Adiciona a classe 'show'
      targetTab.classList.remove('hide'); // Remove a classe 'hide'
    }
  }
  
  // Funções responsaveis pelos efeitos do Menu
  function onMouserHoverMenuDashboard() {
    let addClassAsideSecondary = document.getElementById('AsideSecondary')
    addClassAsideSecondary.classList.add('show-menu')
    addClassAsideSecondary.classList.remove('hide-menu')
  }

  function onMouserHoverOut() {
    let removeClassAsideSecondary = document.getElementById('AsideSecondary')
    removeClassAsideSecondary.classList.remove('show-menu')
    removeClassAsideSecondary.classList.add('hide-menu')
  }

  return (
    <>
      {isMobile ? (
        <div
          id='kt_aside'
          className={clsx('aside all-start-between-column', classes.aside.join(' '))}
          data-kt-drawer='true'
          data-kt-drawer-name='aside'
          data-kt-drawer-activate='{default: true, lg: false}'
          data-kt-drawer-overlay='true'
          data-kt-drawer-direction='start'
          data-kt-drawer-toggle='#kt_aside_mobile_toggle'
          ref={asideRef}
        >
          {/* begin::Brand */}
          <div className='aside-logo flex-column-auto' id='kt_aside_logo'>
            {/* begin::Logo */}
            {aside.theme === 'dark' && (
              <Link to='/dashboard'>
                <img
                  alt='Logo'
                  className='h-25px logo'
                  src={toAbsoluteUrl('/media/logos/default-dark.svg')}
                />
              </Link>
            )}
            <Link to='/dashboard'>
              <img
                alt='Logo'
                className='h-25px logo'
                src={toAbsoluteUrl('/media/logos/default.svg')}
              />
            </Link>

            {aside.minimize && (
              <div
                id='kt_aside_toggle'
                className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle'
                data-kt-toggle='true'
                data-kt-toggle-state='active'
                data-kt-toggle-target='body'
                data-kt-toggle-name='aside-minimize'
                onClick={minimize}
              >
                <KTSVG
                  path={'/media/icons/duotune/arrows/arr080.svg'}
                  className={'svg-icon-1 rotate-180'}
                />
              </div>
            )}
          </div>

          {/* begin::Aside menu */}
          <div className='aside-menu flex-column-fluid'>
            <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
          </div>
          {/* end::Aside menu */}
          {/* begin::Footer */}
          <div className='aside-footer flex-column gap-3 pt-5 pb-7 px-5 w-100 all-center' id='kt_aside_footer'>
            <Link to='/tutorial'
              className='btn btn-custom btn-success w-100'
            >
              <span className='btn-label'>Tutoriais</span>
            </Link>

            <Link to='/logout'
              className='btn btn-custom btn-primary w-100'
            >
              <span className='btn-label'>Sair</span>
            </Link>
          </div>
          {/* end::Footer */}
        </div>
      ) : (
        <div
          id='kt_aside'
          className='aside'
          data-kt-drawer='false'
          data-kt-drawer-name='aside'
          data-kt-drawer-activate='{default: true, lg: false}'
          data-kt-drawer-overlay='true'
          data-kt-drawer-width="{default:'200px', '300px': '250px'}"
          data-kt-drawer-direction='start'
          ref={asideRef}
          // heigth={window.innerHeight}
          style={{ height: window.innerHeight }}
        >
          <div className='aside-primary d-flex flex-column align-items-lg-center flex-row-auto'>
            <div
              className='aside-nav d-flex flex-column align-items-center flex-column-fluid justify-content-center'
              id='kt_aside_nav'
            >
              <div
                className=' d-flex flex-column align-items-center flex-column-fluid justify-content-center'
              >
                <ul className='nav flex-column text-center pt-3'
                  id='menu-principal'>
                  <li className='nav-item  mb-3' title='Dashboard'>
                    <a
                      onClick={() => {
                        handleDashboard()
                        onMouserHoverOut()
                      }}
                      onMouseEnter={() => onMouserHoverOut()}
                      className='nav-link btn btn-icon btn-active-color-primary btn-color-green-900 btn-active-light'
                      data-bs-toggle='tab'
                      href='#dashboard'
                    >
                      <span className='svg-icon svg-icon-2x'>
                        <title>Dashboard</title>
                        <BiHome className='text-gray-700 btn-active-light'></BiHome>
                      </span>
                    </a>
                  </li>
                  <li
                    className='nav-item  mb-3 menu-hover'
                    data-bs-toggle='tooltip'
                    data-bs-trigger='hover'
                    data-bs-placement='right'
                    data-bs-dismiss='click'
                    title='Explorer'
                  >
                    <a
                      onClick={() => {
                        handleExplorer()
                        onMouserHoverOut()
                      }}
                      onMouseEnter={() => onMouserHoverOut()}

                      className='nav-link btn btn-icon btn-active-color-primary btn-color-green-900 text-gray-600 btn-active-light'
                      data-bs-toggle='tab'
                      href='#explorer'
                    >
                      <span className='svg-icon svg-icon-2x'>
                        <title>Explorer</title>
                        <BiCompass className='text-gray-700 btn-active-light'></BiCompass>
                      </span>
                    </a>
                  </li>
                  <li
                    className='nav-item  mb-3'
                    data-bs-toggle='tooltip'
                    data-bs-trigger='hover'
                    data-bs-placement='right'
                    data-bs-dismiss='click'
                    title='Meus Imóveis'
                  >
                    <a
                      href='#meus-imoveis'
                      onClick={() => {
                        handleMeusLocais()
                        onMouserHoverOut()
                      }}
                      onMouseEnter={() => onMouserHoverOut()}
                      className='nav-link btn btn-icon btn-active-color-primary btn-color-green-900 btn-active-light btnHover'
                      data-bs-toggle='tab'
                    >
                      <span className='svg-icon svg-icon-2x'>
                        <title>Meus Imóveis</title>
                        <BiMapPin className='text-gray-700'></BiMapPin>
                      </span>
                    </a>
                  </li>
                  <li
                    className='nav-item  mb-3'
                    data-bs-toggle='tooltip'
                    data-bs-trigger='hover'
                    data-bs-placement='right'
                    data-bs-dismiss='click'
                    title='Empreendimentos'
                  >
                    <a
                      href='#empreendimentos'
                      onClick={() => {
                        handleEmpreendimentos()
                        onMouserHoverOut()
                      }}
                      onMouseEnter={() => {
                        onMouserHoverMenuDashboard()
                        activetab('menu-empreendimentos', 'show')
                      }}
                      // onMouseEnter={() => onMouserHoverOut()}
                      className='nav-link btn btn-icon btn-active-color-primary btn-color-green-900 btn-active-light'
                      data-bs-toggle='tab'
                    >
                      <span className='svg-icon svg-icon-2x'>
                        <title>Empreendimentos</title>
                        <BiBuildings className='text-gray-700'></BiBuildings>
                      </span>
                    </a>
                  </li>
                  <li
                    className='nav-item  mb-3  '
                    data-bs-toggle='tooltip'
                    data-bs-trigger='hover'
                    data-bs-placement='right'
                    data-bs-dismiss='click'
                    title='Cadastro'
                    data-toggle='dropdown'
                    role='button'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <a
                      className='nav-link btn btn-icon btn-active-color-primary btn-color-green-900 btn-active-light'
                      href='#im'
                      data-bs-toggle='tab2'
                      aria-selected='true'
                      role='tab'
                      onMouseEnter={() => {
                        onMouserHoverMenuDashboard()
                        activetab('im', 'show')
                      }}
                    >
                      <span className='svg-icon svg-icon-2x'>
                        <title>Inteligência de Mercado</title>
                        <BiBarChart className='text-gray-700'></BiBarChart>
                      </span>
                    </a>
                  </li>

                  <li
                    className='nav-item  mb-3 hoverContent '
                    data-bs-toggle='tooltip'
                    data-bs-trigger='hover'
                    data-bs-placement='right'
                    data-bs-dismiss='click'
                    title='Cadastro'
                    data-toggle='dropdown'
                    role='button'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <a
                      className='nav-link btn btn-icon btn-active-color-primary btn-color-green-900 btn-active-light'
                      href='#configuracoes'
                      data-bs-toggle='tab'
                      aria-selected='true'
                      role='tab'
                      onMouseEnter={() => {
                        onMouserHoverMenuDashboard()
                        activetab('configuracoes', 'show')
                      }}
                    >
                      <span className='svg-icon svg-icon-2x'>
                        <title>Cadastro</title>
                        <BiCog className='text-gray-700'></BiCog>
                      </span>
                    </a>
                  </li>
                  <li
                    className='nav-item  mb-3 hoverContent '
                    data-bs-toggle='tooltip'
                    data-bs-trigger='hover'
                    data-bs-placement='right'
                    data-bs-dismiss='click'
                    title='Pessoas'
                    data-toggle='dropdown'
                    role='button'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >

                    <a
                      className='nav-link btn btn-icon btn-active-color-primary btn-color-green-900 btn-active-light'
                      data-bs-toggle='tab'
                      href='#pessoas'
                      aria-selected='true'
                      role='tab'
                      onClick={() => {
                        handlePessoas()
                      }}
                      onMouseEnter={() => onMouserHoverOut()}
                    >
                      <span className='svg-icon svg-icon-2x'>
                        <title>Pessoas</title>
                        <AiOutlineUserSwitch className='text-gray-700'></AiOutlineUserSwitch>
                      </span>
                    </a>
                  </li>
                  <li
                    className='nav-item  mb-3 hoverContent '
                    data-bs-toggle='tooltip'
                    data-bs-trigger='hover'
                    data-bs-placement='right'
                    data-bs-dismiss='click'
                    title='Avaliações'
                    data-toggle='dropdown'
                    role='button'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    {/*begin::Nav link*/}
                    <a
                      className='nav-link btn btn-icon btn-active-color-primary btn-color-green-900 btn-active-light'
                      data-bs-toggle='tab'
                      href='#relatorios'
                      aria-selected='true'
                      role='tab'
                      onClick={() => {
                        handleAvaliacoes()
                      }}
                      onMouseEnter={() => onMouserHoverOut()}
                    >
                      <span className='svg-icon svg-icon-2x'>
                        <title>Avaliações</title>
                        <BiPurchaseTagAlt className='text-gray-700'></BiPurchaseTagAlt>
                      </span>
                    </a>
                  </li>

                  <li
                    className='nav-item  mb-3 hoverContent '
                    data-bs-toggle='tooltip'
                    data-bs-trigger='hover'
                    data-bs-placement='right'
                    data-bs-dismiss='click'
                    title='Relatórios'
                    data-toggle='dropdown'
                    role='button'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    {/*begin::Nav link*/}
                    <a
                      className='nav-link btn btn-icon btn-active-color-primary btn-color-green-900 btn-active-light'
                      data-bs-toggle='tab'
                      href='#relatorios'
                      aria-selected='true'
                      role='tab'
                      onMouseEnter={() => {
                        onMouserHoverMenuDashboard()
                        activetab('relatorios', 'show')
                      }}
                    >
                      <span className='svg-icon svg-icon-2x'>
                        <title>Relatórios</title>
                        <BiFile className='text-gray-700'></BiFile>
                      </span>
                    </a>
                  </li>

                  {secureLocalStorage.getItem('profile_id') === '1' ? (
                    <li
                      className='nav-item  mb-3 hoverContent '
                      data-bs-toggle='tooltip'
                      data-bs-trigger='hover'
                      data-bs-placement='right'
                      data-bs-dismiss='click'
                      title='Backoffice'
                      data-toggle='dropdown'
                      role='button'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      {/*begin::Nav link*/}
                      <a
                        className='nav-link btn btn-icon btn-active-color-success btn-color-green-900 btn-active-light'
                        data-bs-toggle='tab'
                        href='#backoffice'
                        aria-selected='true'
                        role='tab'
                        onClick={() => {
                          onMouserHoverMenuDashboard()
                          activetab('backoffice', 'show')
                        }}
                      >
                        <span className='svg-icon svg-icon-2x'>
                          <title>Backoffice</title>
                          <BiWrench className='text-gray-700 '></BiWrench>
                        </span>
                        {/*end::Svg Icon*/}
                      </a>
                    </li>
                  ) : (
                    ''
                  )}

                </ul>

              </div>

            </div>

            <div
              className='aside-footer d-flex flex-column align-items-center flex-column-auto'
              id='kt_aside_footer'
              // style={{ height: 200 }}
              ref={asideFooter}
            >
              {/*begin::Wrapper*/}
              <div
                className=' d-flex flex-column align-items-start flex-column-fluid justify-content-center'
              // data-kt-scroll='true'
              // data-kt-scroll-activate='{default: false, lg: true}'
              // data-kt-scroll-height='auto'
              // data-kt-scroll-wrappers='#kt_aside_nav'
              // data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer'
              // data-kt-scroll-offset='0px'
              >
                <ul className='nav flex-column  text-center'>
                  {/*begin::Nav item*/}
                  <li className='nav-item' title='Tutoriais'>
                    <Link
                      to='/tutorial'
                      className='nav-link btn btn-icon btn-active-color-success btn-color-green-900 btn-active-light'
                      data-kt-menu-trigger='click'
                      data-kt-menu-overflow='true'
                      data-kt-menu-placement='top-start'
                      data-bs-toggle='tooltip'
                      data-bs-placement='right'
                      data-bs-dismiss='click'
                      title='Tutoriais'
                    >
                      <span className='svg-icon svg-icon-2x'>
                        <FiHelpCircle></FiHelpCircle>
                      </span>
                    </Link>
                  </li>
                  {/*end::Nav item*/}
                  {/*begin::Nav item*/}
                  <li className='nav-item' title='Logout'>
                    <Link
                      to='/logout'
                      className='nav-link btn btn-icon btn-active-color-success btn-color-green-900 btn-active-light'
                      data-kt-menu-trigger='click'
                      data-kt-menu-overflow='true'
                      data-kt-menu-placement='top-start'
                      data-bs-toggle='tooltip'
                      data-bs-placement='right'
                      data-bs-dismiss='click'
                      title='Sair'
                    >
                      <span className='svg-icon svg-icon-2x'>
                        <BiLogOut></BiLogOut>
                      </span>
                    </Link>
                  </li>
                  {/*end::Nav item*/}
                </ul>
              </div>


            </div>
            {/*end::Footer*/}
          </div>
          {/*end::Primary*/}

          <MenuSecondary onMouserHoverOut={onMouserHoverOut} />
        </div>
      )}

    </>
  )
}

export { AsideDefault }
