import React, {useState} from 'react'
import {Buttons} from './Buttons'
import {QueryGetImoveis} from '../../../../graphql'
import {CreateImovel} from './create'
import PaginationComponent from '../../../../components/UI/PaginationComponents'
import {CardTables} from '../../../../components/UI/CardTables'
import LoadingTable from '../../../../components/UI/CardTables/LoadingTable'
import ErrorTable from '../../../../components/UI/CardTables/ErrorTable'

type Props = {
  active?: boolean
  filtro: any[]
  objectFilter: {}
}

const TableMeusImoveis: React.FC<Props> = ({active, filtro, objectFilter}) => {
  const [pagiancao, setPaginacao] = useState({pagina: 0, quantidade: 10})
  const {data, loading, error} = QueryGetImoveis({
    variables: {
      pagination: {
        pagina: pagiancao.pagina,
        quantidade: pagiancao.quantidade,
      },
      filtro: {
        fields: filtro,
        ...objectFilter
      },
    },
  })



  return (
    <>
      <CardTables
        headerTitle={'Meus Imóveis'}
        headerSubtTitle={`Até o momento ${
          data ? data.GetImoveis.pageInfo.totalItems : 0
        } imóveis registrados.`}
        headerToolbar={<CreateImovel />}
        footer={
          data ? (
            <PaginationComponent
              pagesInfo={data.GetImoveis.pageInfo}
              setPagesInfo={(pagina: number, quantidade: number) => {
                setPaginacao({pagina: pagina, quantidade: quantidade})
              }}
            ></PaginationComponent>
          ) : (
            <></>
          )
        }
      >
        <div className='table-responsive  '>
          <table className='table table-striped table-row-dashed  gy-2 gs-6  table-row-bordered   table-hover  table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th className='min-w-50px'>ID</th>
                <th className='min-w-50px'>Situação</th>
                <th className='min-w-50px'>Nome</th>
                <th className='min-w-140px'>Número Contribuinte</th>
                <th className='min-w-140px'>Endereço</th>
                <th className='min-w-50px'>Numero</th>
                <th className='min-w-50px'>CEP</th>
                <th className='min-w-150px text-end'>Ações</th>
              </tr>
            </thead>
            <tbody>
              <LoadingTable loading={loading} />
              <ErrorTable error={error} />
              {data ? (
                data.GetImoveis.result.length > 0 ? (
                  data.GetImoveis.result.map((response) => {
                    return (
                      <tr key={response.id}>
                        <td>{response.id}</td>
                        <td className='d-flex justify-content-center align-items-center gap-1'>
                          <div>
                            <span  className='badge  fw-bold me-auto px-1 py-2'style={{backgroundColor:response.terreno_situacao_cliente?.cor,color:response.terreno_situacao_cliente?.cor}}>.</span> <span className='flex-fill'> {response.terreno_situacao_cliente?.nome}</span>
                          </div>
                          </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                              <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                {response.nome}
                              </span>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                {response.users.name}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>{response.numero_contribuinte}</td>
                        <td>{response.endereco}</td>
                        <td>{response.numero}</td>
                        <td>{response.cep}</td>
                        
                        <td className='text-left w-200px'>
                          <Buttons id={response.id} id_user={response.users.id}/>
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={12} className='text-center'>
                      Nenhum registro encontrado
                    </td>
                  </tr>
                )
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </CardTables>
    </>
  )
}

export {TableMeusImoveis}
