import { z } from "zod"

export const setImovelCorretorFormSchema = z.object({
    id_terreno: z.number(),
    id_corretor: z.number()
})

export type setImovelCorretorFormData = z.infer<typeof setImovelCorretorFormSchema>

export const setImovelCorretorByIDFormSchema = z.object({
    nome: z.string().trim().min(1, { message: 'Campo Obrigatório.'}),
    telefone: z.string().trim().min(8, { message: 'Campo Obrigatório.'}),
    email: z.string().trim().min(1, { message: 'Campo Obrigatório.'}),
    empresa: z.string(),
    idImovel: z.number()
})

export type setImovelCorretorByIDFormData = z.infer<typeof setImovelCorretorByIDFormSchema>