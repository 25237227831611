export function toDegrees(rad) {
    return rad * (180 / Math.PI)
  }
  export  function toRadians(deg) {
    return deg * (Math.PI / 180)
  }
  
export  function midpoint(p1, p2) {
    const lat1 = toRadians(p1.lat)
    const lon1 = toRadians(p1.lng)
    const lat2 = toRadians(p2.lat)
    const lon2 = toRadians(p2.lng)
  
    const dLon = lon2 - lon1
  
    // Calcular as coordenadas cartesianas intermediárias
    const Bx = Math.cos(lat2) * Math.cos(dLon)
    const By = Math.cos(lat2) * Math.sin(dLon)
  
    // Calcular a latitude e a longitude médias
    const latMid = Math.atan2(
      Math.sin(lat1) + Math.sin(lat2),
      Math.sqrt((Math.cos(lat1) + Bx) * (Math.cos(lat1) + Bx) + By * By)
    )
    const lonMid = lon1 + Math.atan2(By, Math.cos(lat1) + Bx)
  
    return {
      lat: toDegrees(latMid),
      lng: toDegrees(lonMid),
    }
  }