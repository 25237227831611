import {Text, View} from '@react-pdf/renderer'
import {styles} from '../style'

export const AddressesExtended = ({data, estrutura}) => {
  let dateReturn = null
  if (typeof data['Result'] !== 'undefined') {
    dateReturn = data['Result'][0]['ExtendedAddresses']['Addresses']
  } else {
    if (typeof data[0]['ExtendedAddresses'] !== 'undefined'){
      dateReturn = data[0]['ExtendedAddresses']['Addresses']
    }
  }

  return (
    <>
      <View>
        {dateReturn?.map((column, index2) => {
          return (
            <View
              key={index2}
              style={[styles.row, styles.border, {paddingTop: 10, paddingBottom: 10}]}
              wrap={false}
            >
              <View>
                <View style={styles.row}>
                  <View style={styles.groupInfo}>
                    <Text style={[styles.h4, styles.bold]}>
                      Endereço:
                      <Text style={styles.h4}>
                        {column.Typology} {column.AddressMain},{column.Number} ({column.Type})
                      </Text>
                    </Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.groupInfo}>
                    <Text style={[styles.h4, styles.bold]}>
                      Complemento:
                      <Text style={styles.h4}>{column.Complement}</Text>
                    </Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.groupInfo}>
                    <Text style={[styles.h4, styles.bold]}>
                      Bairro:
                      <Text style={styles.h4}>{column.Neighborhood}</Text>
                    </Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.groupInfo}>
                    <Text style={[styles.h4, styles.bold]}>
                      Cidade:
                      <Text style={styles.h4}>{column.City}</Text>
                    </Text>
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.groupInfo}>
                    <Text style={[styles.h4, styles.bold]}>
                      Estado:
                      <Text style={styles.h4}>{column.State}</Text>
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          )
        })}
      </View>
    </>
  )
}
