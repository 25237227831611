import {Modal, Tab, Tabs} from 'react-bootstrap'
import {useState} from 'react'
import {MutationSetEmpreendimentoImovelCorretores} from '../../../../../../graphql'
import {GetCorretoresChosen} from '../../../../../../components/UI/Chosens/GetCorretoresChosen'
import {FormInputAndLabel} from '../../../../../../components/UI/Inputs/FormInputAndLabel'
import {FormInputMaskAndLabel} from '../../../../../../components/UI/Inputs/FormInputMaskAndLabel'
import {ErrorMessageComponent} from '../../../../../../backoffice/components/UI/ErrorMessage'
import Swal from 'sweetalert2'
import {MutationSetLogAcessoRecurso} from '../../../../../../graphql/services/LogAcesso'

interface QueryGetCorretores {
  id_empreendimento: number
}

export function CreateFormImovelCorretor({id_empreendimento}: QueryGetCorretores) {
  const [show, setShow] = useState(false)
  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()
  const {
    FormEmpreendimentoImovelCorretor,
    errors,
    reset,
    setValue,
    handleSubmit: HabdleSubmitImovelCorretor,
    loading: LoadingImovelCorretor,
    register,
  } = MutationSetEmpreendimentoImovelCorretores()

  function closeModal() {
    setShow(false)
  }
  function openModal() {
    setShow(true)
  }

  const onSubmit = async (data) => {
    FormEmpreendimentoImovelCorretor(data)
      .then((result) => {
        FormSetLogAcessoRecurso({
          ferramenta: 'cadastro',
          funcionalidade: 'cadastro-corretor-empreendimento',
          action: 'add',
          url: `/empreendimentos/details/${id_empreendimento}`,
        })
        if (result) {
          Swal.fire('Sucesso!', 'Cadastrado com sucesso.', 'success')
          closeModal()
          reset()
        }
      })
      .catch((error) => {
        Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
      })
  }

  function changeType(type: string) {
    setValue('type', type)
  }

  return (
    <>
      <button onClick={openModal} className='btn  btn-light-info btn-sm me-2'>
        <span className='svg-icon svg-icon-2'>
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M6.28548 15.0861C7.34369 13.1814 9.35142 12 11.5304 12H12.4696C14.6486 12 16.6563 13.1814 17.7145 15.0861L19.3493 18.0287C20.0899 19.3618 19.1259 21 17.601 21H6.39903C4.87406 21 3.91012 19.3618 4.65071 18.0287L6.28548 15.0861Z'
              fill='currentColor'
            ></path>
            <rect opacity='0.3' x='8' y='3' width='8' height='8' rx='4' fill='currentColor'></rect>
          </svg>
        </span>
        Adicionar Corretor
      </button>

      <Modal centered animation show={show} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Associar Corretor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs defaultActiveKey='search' id='uncontrolled-tab-example' className='mb-3'>
            <Tab eventKey='search' title='Buscar' onClick={() => changeType('existente')}>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='form-group'>
                    <form onSubmit={HabdleSubmitImovelCorretor(onSubmit)} id='form-corretor'>
                      <input
                        className='form-control form-control-solid'
                        placeholder='id'
                        hidden={true}
                        defaultValue={id_empreendimento}
                        {...register('id_empreendimento', {required: true, valueAsNumber: true})}
                      />
                      <GetCorretoresChosen
                        valueCorretor={null}
                        SetValueCorretor={(value: number) => {
                          setValue('id_corretor', value)
                          // setValueCorretor(value)
                        }}
                      />
                      {errors && <ErrorMessageComponent message={errors.id_corretor?.message} />}
                      <div className='col-md-12 d-flex mrt-1' style={{justifyContent: 'flex-end'}}>
                        <button
                          type='button'
                          className='btn btn-default pull-right'
                          data-bs-dismiss='modal'
                          onClick={() => closeModal()}
                        >
                          Fechar
                        </button>
                        <button
                          type='submit'
                          className='btn btn-success mx-2'
                          id='btnIncluirCorretor'
                        >
                          Associar
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Tab>

            <Tab eventKey='novo' title='Incluir Novo' onClick={() => changeType('novo')}>
              <form onSubmit={HabdleSubmitImovelCorretor(onSubmit)}>
                <input
                  className='form-control form-control-solid'
                  placeholder='id'
                  hidden={true}
                  defaultValue={id_empreendimento}
                  {...register('id_empreendimento', {required: true, valueAsNumber: true})}
                />
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='mb-6'>
                      <FormInputAndLabel
                        label='Nome Completo'
                        className='form-control required form-control-solid'
                        placeholder='Nome Completo'
                        register={register('corretor.nome')}
                        error={errors ? errors.corretor?.nome?.message : ''}
                      />
                    </div>
                  </div>

                  <div className='col-md-12'>
                    <div className='mb-6'>
                      <FormInputAndLabel
                        label='Email'
                        className='form-control required form-control-solid'
                        placeholder='Ex: joao@email.com'
                        register={register('corretor.email')}
                        error={errors ? errors.corretor?.email?.message : ''}
                      />
                    </div>
                  </div>

                  <div className='col-md-12'>
                    <div className='mb-6'>
                      <FormInputMaskAndLabel
                        label='Telefone:'
                        maskChar=' '
                        mask='(99) 99999-9999'
                        className='form-control form-control-solid'
                        placeholder='Ex: (00) 00000-0000'
                        register={register('corretor.telefone')}
                        error={errors ? errors.corretor?.telefone?.message : ''}
                      />
                    </div>
                  </div>

                  <div className='col-md-12'>
                    <div className='mb-6'>
                      <FormInputAndLabel
                        label='Empresa'
                        className='form-control required form-control-solid'
                        placeholder='Empresa'
                        register={register('corretor.empresa')}
                        error={errors ? errors.corretor?.empresa?.message : ''}
                      />
                    </div>
                  </div>
                  <div>{errors?.corretor ? errors.corretor.root.message : ''}</div>
                  <div className='col-md-12 py-6 d-flex' style={{justifyContent: 'flex-end'}}>
                    <button
                      className='btn btnSecondary pull-right '
                      type='button'
                      onClick={closeModal}
                    >
                      Fechar
                    </button>
                    <button
                      disabled={LoadingImovelCorretor}
                      type='submit'
                      className='btn btn-success mx-2'
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              </form>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    </>
  )
}
