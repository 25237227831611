import { Modal } from 'react-bootstrap'
import { ErrorMessageComponent } from '../../../../backoffice/components/UI/ErrorMessage'
import Swal from 'sweetalert2'
import { Loader } from '../../../../components/UI/Loader/Loader'
import { MutationSetLogAcessoRecurso } from '../../../../graphql/services/LogAcesso'
import { useState } from 'react'
import { MutationSetEquipe } from '../../../../graphql/services/Equipes'

interface Props {
  closeModal: any
  show: boolean
}

export function CreateForm({ closeModal, show }: Props) {
  const [color, setColor] = useState(null)

  const { FormEquipe, loading, handleSubmit, register, errors } =
    MutationSetEquipe()
  const { FormSetLogAcessoRecurso } = MutationSetLogAcessoRecurso()

  const onSubmit = async (data) => {
    FormEquipe(data)
      .then((result) => {
        FormSetLogAcessoRecurso({
          ferramenta: 'cadastro',
          funcionalidade: 'equipes',
          action: 'create',
          url: '/cadastro/equipes',
        })
        Swal.fire('Sucesso!', 'Equipe criada com sucesso.', 'success')
        closeModal()
      })
      .catch((error) => {
        Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
      })
  }

  // Tratativa de loading
  if (loading) {
    return <Loader text={'caregando...'}></Loader>
  }

  return (
    <Modal centered size='sm' animation show={show} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Nova Equipe</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='row'>
            <div className='col-md-12'>
              <div className='mb-6'>
                <label htmlFor='nome' className='required form-label'>
                  Nome:
                </label>
                <input
                  className='form-control form-control-solid'
                  placeholder='Nome da equipe'
                  defaultValue={''}
                  {...register('nome', {
                    pattern: /^[A-Za-z]+$/i,
                  })}
                />
                {errors.nome && <ErrorMessageComponent message={errors.nome?.message} />}
              </div>
            </div>


            <div className='col-md-12 d-flex' style={{ justifyContent: 'flex-end' }}>
              <button className='btn btnSecondary  pull-right ' type='button' onClick={closeModal}>
                Fechar
              </button>
              <button disabled={loading} type='submit' className='btn btn-success mx-2'>
                Salvar
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}
