import { gql } from "@apollo/client";

export const GET_EMPREENDIMENTO_TEMPERATURA = gql`
query GetEmpreendimentoTemperatura($pagination: Pagination) {
  GetEmpreendimentoTemperatura(pagination: $pagination) {
    result {
      id
      uuid
      nome
      situacao
      cor
    }
    pageInfo {
      currentPage
      totalPages
      totalItems
      hasNextPage
      hasPreviousPage
    }
  }
}
`

export const GET_EMPREENDIMENTO_TEMPERATURA_BY_ID = gql`
query GetEmpreendimentoTemperaturaById($getEmpreendimentoTemperaturaByIdId: Float!) {
  GetEmpreendimentoTemperaturaById(id: $getEmpreendimentoTemperaturaByIdId) {
    id
    uuid
    nome
    situacao
    cor
  }
}
`