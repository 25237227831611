import {Modal} from 'react-bootstrap'
import Swal from 'sweetalert2'
import {ErrorMessageComponent} from '../../../../backoffice/components/UI/ErrorMessage'
import {Loader} from '../../../../components/UI/Loader/Loader'
import {MutationSetLogAcessoRecurso} from '../../../../graphql/services/LogAcesso'
import {useState} from 'react'
import {RgbToHex} from '../../../explorer/components/common'

import {
  MutationPutEmpreendimentoSituacao,
  QueryGetEmpreendimentoSituacaoByID,
} from '../../../../graphql/services/EmpreendimentoSituacao'
import { ColorPickerChorme } from '../../../../components/UI/ColorPickerChorme'
import { Controller } from 'react-hook-form'

interface Props {
  id: number
  show: boolean
  closeModal: any
}

export function EditForm({id, show, closeModal}: Props) {
  const [color, setColor] = useState(null)

  let {data, loading: LoadingGet} = QueryGetEmpreendimentoSituacaoByID({
    variables: {getEmpreendimentoSituacaoByIdId: id},
  })

  const {FormEmpreendimentoSituacao, loading, handleSubmit, register, setValue, errors,control} =
    MutationPutEmpreendimentoSituacao()

  const {FormSetLogAcessoRecurso} = MutationSetLogAcessoRecurso()

  const onSubmit = async (data) => {
    FormEmpreendimentoSituacao(data)
      .then((result) => {
        FormSetLogAcessoRecurso({
          ferramenta: 'cadastro',
          funcionalidade: 'situacao-terrenos',
          action: 'update',
          url: '/cadastro/situacao-terrenos',
        })
        Swal.fire('Sucesso!', 'Dados atualizados com sucesso.', 'success')
        closeModal()
      })
      .catch((error) => {
        Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
      })
  }

  // Tratativa de loading
  if (loading || LoadingGet) {
    return <Loader text={'caregando...'}></Loader>
  }

  const onChange = (color: number[]) => {
    const hexColor = RgbToHex(color[0], color[1], color[2])
    setValue('cor', hexColor, {shouldValidate: true}) // Atualizando o campo 'cor'
  }

  if (loading) {
    return <Loader text='Carregando...' />
  }

  return (
    <>
      <Modal centered animation show={show} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Situação do Empreendimento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              className='form-control form-control-solid'
              type='number'
              hidden={true}
              defaultValue={id ? id : ''}
              {...register('id', {required: true, valueAsNumber: true})}
            />
            <div className='row'>
              <div className='col-md-12'>
                <div className='mb-6'>
                  <label htmlFor='nome' className='required form-label'>
                    Nome:
                  </label>
                  <input
                    className='form-control form-control-solid'
                    placeholder='Nome da Situação'
                    defaultValue={data ? data.GetEmpreendimentoSituacaoById.nome : ''}
                    {...register('nome', {
                      pattern: /^[A-Za-z]+$/i,
                     
                    })}
                  />
                  {errors.nome && <ErrorMessageComponent message={errors.nome?.message} />}
                </div>
              </div>

              <div className='mb-15 col-12'>
                <div className=''>
                  <label htmlFor='descricao' className='required form-label'>
                    Cor
                  </label>
                    <ColorPickerChorme
                    defaultValue={data ? data.GetEmpreendimentoSituacaoById.cor : null}
                    onChange={onChange} 
                    register= {register('cor')}
                  />
                  {errors.cor && <ErrorMessageComponent message={errors.cor?.message} />}
                </div>
              </div>
              <div className='col-md-12 py-6 d-flex' style={{justifyContent: 'flex-end'}}>
                <button
                  className='btn btnSecondary  pull-right '
                  type='button'
                  onClick={() => closeModal()}
                >
                  Fechar
                </button>
                <button disabled={loading} type='submit' className='btn btn-success mx-2'>
                  Salvar
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
