import {MapControll} from '../../../../../../store/mapControll'
import {mapStore} from '../../../../../../store/mapStore'
import {DrawInterface} from './DrawInterface'




export class DrawCircle implements DrawInterface {
  active: boolean

  constructor() {
    this.active = false
  }
  controllMove(event: any, callback: any) {
    console.log(event)
    console.log('controlmove')

  }
  controllClick(): string {
    throw new Error('Method not implemented.')
  }
  setActive(active) {
    const {draw} = mapStore.getState()
    if (!active) {
        MapControll.setMode('')
        draw['setDrawingMode']('')
        // MapControll.setInfoBox('distancia', '', false)
        this.active = false
    } else {
        draw['setDrawingMode']('circle')
        MapControll.setMode('DRAW_CIRCLE')
        // MapControll.setInfoBox('distancia', '0m', true)
        this.active = active
    }
  }
}

