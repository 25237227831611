import {gql} from '@apollo/client'

export const GET_EMPREENDIMENTO_TEMPERATURA = gql`
  query GetEmpreendimentoTemperatura($pagination: Pagination!) {
    GetEmpreendimentoTemperatura(pagination: $pagination) {
      pageInfo {
        currentPage
        totalPages
        totalItems
        hasNextPage
        hasPreviousPage
      }
      result {
        id
        nome
        situacao
        cor
      }
    }
  }
`
