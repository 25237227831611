import {MenuBackoffice} from './MenuItens/MenuBackoffice'
import {MenuCadastro} from './MenuItens/MenuCadastro'
import { MenuEmpreendimentos } from './MenuItens/MenuEmpreendimentos'
import { MenuIM } from './MenuItens/MenuIM'
import { MenuRelatorios } from './MenuItens/MenuRelatorios'

export function MenuSecondary({onMouserHoverOut}) {
  return (
    <div className='aside-secondary  hide-menu' id='AsideSecondary' onMouseLeave={onMouserHoverOut}>
      <div className='aside-workspace my-5 p-5'>
        <div className='d-flex flex-column'>
          <div
            className='flex-column-fluid'
            data-kt-scroll='true'
            data-kt-scroll-activate='true'
            data-kt-scroll-wrappers='#kt_aside_wordspace'
            data-kt-scroll-dependencies='#kt_aside_secondary_footer'
            data-kt-scroll-offset='0px'
          >
            <div className='tab-content'>

              <div className='tab-pane fade ' id='backoffice' role='tabpanel'>
                <div
                  className='menu menu-column menu-fit menu-rounded menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-semibold fs-5 px-6 my-5 my-lg-0'
                  id='kt_aside_menu'
                  data-kt-menu='true'
                >
                  <div id='kt_aside_menu_wrapper' className='menu-fit'>
                    <MenuBackoffice />
                  </div>
                </div>
              </div>

              <div className='tab-pane fade ' id='relatorios' role='tabpanel'>
                <div
                  className='menu menu-column menu-fit menu-rounded menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-semibold fs-5 px-2 my-5 my-lg-0'
                  id='kt_aside_menu_config'
                  data-kt-menu='true'
                >
                  <div id='kt_aside_menu_wrapper' className='menu-fit'>
                    <MenuRelatorios />
                  </div>
                </div>
              </div>

              <div className='tab-pane fade' id='configuracoes' role='tabpanel'>
                <div
                  className='menu menu-column menu-fit menu-rounded menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-semibold fs-5 px-2 my-5 my-lg-0'
                  id='kt_aside_menu_config'
                  data-kt-menu='true'
                >
                  <div id='kt_aside_menu_wrapper' className='menu-fit'>
                    <MenuCadastro />
                  </div>
                </div>
              </div>


              <div className='tab-pane fade' id='im' role='tabpanel'>
                <div
                  className='menu menu-column menu-fit menu-rounded menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-semibold fs-5 px-2 my-5 my-lg-0'
                  id='kt_aside_menu_im'
                  data-kt-menu='true'
                >
                  <div id='kt_aside_menu_wrapper' className='menu-fit'>
                    <MenuIM />
                  </div>
                </div>
              </div>

              <div className='tab-pane fade' id='menu-empreendimentos' role='tabpanel'>
                <div
                  className='menu menu-column menu-fit menu-rounded menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-semibold fs-5 px-2 my-5 my-lg-0'
                  id='kt_aside_menu_im'
                  data-kt-menu='true'
                >
                  <div id='kt_aside_menu_wrapper' className='menu-fit'>
                    <MenuEmpreendimentos />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
