import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {mapStore} from '../../../../../../../store/mapStore'
import {midpoint} from '../Calcs'

function getOverlay(position, content) {
  class CustomPopup extends google.maps.OverlayView {
    position: google.maps.LatLng
    containerDiv: HTMLDivElement

    constructor(position: google.maps.LatLng, content: string) {
      super()
      this.position = position

      const contentDiv = document.createElement('div')
      contentDiv.id = 'contentPopup'
      contentDiv.innerHTML = content
      contentDiv.classList.add('popup-bubble')

      // Âncora da bolha
      const bubbleAnchor = document.createElement('div')
      bubbleAnchor.classList.add('popup-bubble-anchor')
      bubbleAnchor.appendChild(contentDiv)

      // Container principal
      this.containerDiv = document.createElement('div')
      this.containerDiv.classList.add('popup-container')
      this.containerDiv.appendChild(bubbleAnchor)

      google.maps.OverlayView.preventMapHitsAndGesturesFrom(this.containerDiv)
    }

    onAdd() {
      this.getPanes()?.floatPane.appendChild(this.containerDiv)
    }

    onRemove() {
      if (this.containerDiv.parentElement) {
        this.containerDiv.parentElement.removeChild(this.containerDiv)
      }
    }

    draw() {
      const divPosition = this.getProjection().fromLatLngToDivPixel(this.position)
      if (divPosition) {
        const display =
          Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? 'block' : 'none'

        if (display === 'block') {
          this.containerDiv.style.left = `${divPosition.x}px`
          this.containerDiv.style.top = `${divPosition.y}px`
        }

        if (this.containerDiv.style.display !== display) {
          this.containerDiv.style.display = display
        }
      }
    }
  }

  return new CustomPopup(position, content)
}

export class DrawAreaClass {
  active: boolean
  polygonClosed: boolean
  marker: any[]
  path: any[]
  paths: any[]
  infoWindow: any[]
  // popup: any
  measureClickCount: number
  static polygon: google.maps.Polygon
  static polygonFinal: google.maps.Polygon
  constructor() {
    this.active = false
    this.marker = []
    this.path = []
    this.paths = []
    DrawAreaClass.polygon = null
    DrawAreaClass.polygonFinal = null
    this.infoWindow = []
    this.measureClickCount = 0
    // this.popup = popup
  }
  setActive(active) {
    const {map} = mapStore.getState()
    if (!active) {
      this.clearPopup()
      this.clearPolygon()
      this.clearMarker()
    } else {
      map?._map.setOptions({draggableCursor: 'crosshair'})
      this.active = active
    }
  }
  setMeassureActive() {
    const {map} = mapStore.getState()
    this.active = true
    map?._map.setOptions({draggableCursor: 'crosshair'})
  }
  setMeassureDeactive() {
    const {map} = mapStore.getState()
    this.active = false
    map?._map.setOptions({draggableCursor: ''})
  }

  controllClick(event: any, callback: any) {
    if (this.active === true && !this.polygonClosed) {
      this.setMeassureClickCount()
      this.addMark(this.getMeassureClickCount(), event.latLng.toJSON())
      this.paths.push(event.latLng.toJSON())
    }
    callback(this.marker, this.active)
  }
  setMarker(m: any) {
    this.marker.push(m)
  }
  setPath(m: any) {
    this.path.push(m)
  }
  setInfoWindow(m: any) {
    this.infoWindow.push(m)
  }
  clearMarker() {
    // eslint-disable-next-line array-callback-return
    this.marker.map((itens) => {
      itens.setMap(null)
    })
    this.marker = []
    // eslint-disable-next-line array-callback-return
    this.path.map((itens) => {
      itens.setMap(null)
    })
    this.path = []

    
    if (DrawAreaClass.polygon){
      // eslint-disable-next-line array-callback-return
      DrawAreaClass.polygon.setMap(null)

    }
    

    // eslint-disable-next-line array-callback-return
    this.infoWindow.map((itens) => {
      itens.setMap(null)
    })
    this.infoWindow = []
  }
  addPath(path) {
    const {map} = mapStore.getState()
    const Path = new google.maps.Polygon({
      paths: path,
      geodesic: true,
      strokeColor: '#FF0000',
      strokeOpacity: 1.0,
      strokeWeight: 2,
    })
    this.setPath(Path)
    Path.setMap(map._map)
    DrawAreaClass.polygon = Path
  }
  clearPolygon() {
    this.polygonClosed = false
    if (DrawAreaClass.polygonFinal) {
      DrawAreaClass.polygonFinal.setMap(null)
    }
    // DrawAreaClass.polygon.setMap(null)
  }
  clearPopup() {
    Array.from(document.getElementsByClassName('popup-container')).forEach((el) => {
      // Do stuff here
      el.remove()
    })
  }
  drawLines() {
    let result = 0
    const {map} = mapStore.getState()

    // eslint-disable-next-line array-callback-return
    this.marker.map((item, index, itens) => {
      if (index > 0) {
        this.clearPopup()

        let p1 = itens[index - 1]
        let p2 = itens[index]
        const pathCoordinates = [
          {lat: p1.getPosition().lat(), lng: p1.getPosition().lng()},
          {lat: p2.getPosition().lat(), lng: p2.getPosition().lng()},
        ]
        result = this.haversine_distance(p1, p2)
        const midpointCoords = midpoint(
          {lat: p1.getPosition().lat(), lng: p1.getPosition().lng()},
          {lat: p2.getPosition().lat(), lng: p2.getPosition().lng()}
        )

        try {
          let popupn = getOverlay(
            new google.maps.LatLng(midpointCoords),
            `<div>Distância: ${(result * 1000).toFixed(0)}m</div>`
          )
          popupn.setMap(map._map)
        } catch (e) {
          console.log(e)
        }

        this.addPath(pathCoordinates)
      }
    })
  }
  closePolygon(event) {
    if (this.polygonClosed) return

    this.polygonClosed = true

    const {map} = mapStore.getState()

    this.setMeassureClickCount()
    this.addMark(this.getMeassureClickCount(), event.latLng.toJSON())
    DrawAreaClass.polygon.setMap(null)
    const newPolygon = new google.maps.Polygon({
      paths: this.paths,
      // editable:true,
      geodesic: true,
      strokeColor: '#FF0000',
      fillColor: '#FF0000',
      strokeOpacity: 1.0,
      strokeWeight: 2,
      map: map._map,
    })
    DrawAreaClass.polygonFinal = newPolygon

    const area = google.maps.geometry.spherical.computeArea(newPolygon.getPath())
    const center = this.getPolygonCenter(newPolygon.getPath())

    try {
      let popupn = getOverlay(
        new google.maps.LatLng(center),
        `<div>Área: ${area.toFixed(0)} m²</div>`
      )
      popupn.setMap(map._map)
    } catch (e) {
      console.log(e)
    }

    this.marker.forEach((marker) => marker.setMap(null))
    this.marker = []
    DrawAreaClass.polygon.setMap(null)
  }
  getPolygonCenter(path: google.maps.MVCArray<google.maps.LatLng>): google.maps.LatLng {
    let latSum = 0
    let lngSum = 0
    const len = path.getLength()

    for (let i = 0; i < len; i++) {
      latSum += path.getAt(i).lat()
      lngSum += path.getAt(i).lng()
    }

    return new google.maps.LatLng(latSum / len, lngSum / len)
  }
  static getPolygon(polygon: any) {
    return this.polygon
  }
  addMark(id, latlng) {
    const {map} = mapStore.getState()

    const mark = new google.maps.Marker({
      icon: {
        url: toAbsoluteUrl('/media/dot-measure.png'),
        anchor: new google.maps.Point(3, 3),
      },
      position: latlng,
      map: map._map,
      title: 'p' + id,
      optimized: true,
    })

    if (this.marker.length === 0) {
      mark.addListener('click', (event) => this.closePolygon(event))
    }

    this.setMarker(mark)
    this.drawLines()
  }

  haversine_distance(mk1, mk2) {
    var R = 6371 // Radius of the Earth in miles
    var rlat1 = mk1.getPosition().lat() * (Math.PI / 180) // Convert degrees to radians
    var rlat2 = mk2.getPosition().lat() * (Math.PI / 180) // Convert degrees to radians
    var difflat = rlat2 - rlat1 // Radian difference (latitudes)
    var difflon = (mk2.getPosition().lng() - mk1.getPosition().lng()) * (Math.PI / 180) // Radian difference (longitudes)

    var d =
      2 *
      R *
      Math.asin(
        Math.sqrt(
          Math.sin(difflat / 2) * Math.sin(difflat / 2) +
            Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)
        )
      )
    return d
  }
  getMarkers() {
    return this.marker
  }
  getMeassureClickCount() {
    return this.measureClickCount
  }
  setMeassureClickCount() {
    this.measureClickCount = this.measureClickCount + 1
  }
  resetMeassureClickCount() {
    this.measureClickCount = -1
  }
  clearMeassureClickCount() {
    this.measureClickCount = -1
  }
}
