/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { Dropdown1 } from '../../../../_metronic/partials/content/dropdown/Dropdown1'
import { Loader } from '../../../components/UI/Loader/Loader'
import { QueryGetEmpreendimentosRelatorio } from '../../../graphql/services/Empreendimento'
import { formatNumber } from '../../explorer/components/common'

type Props = {
  className: string
  title: string
  icon: string
  filtro: any[]
  objectFilter: any
}

const InfosRelatorios: React.FC<Props> = ({ className, title, icon,filtro,objectFilter }) => {


  
  const {data} = QueryGetEmpreendimentosRelatorio({
    variables: {
      filtro: {
        fields: filtro,
        ...objectFilter,
      },
      // situation: result,
    },
  })  

  if (!data){
    return (<Loader text={'Carregando...'} ></Loader>)
  }
  
  return (
    <div className='card card-xl-stretch '>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <div className='d-flex all-center pb-5 pt-5   ps-5'>
          <span className='svg-icon svg-icon-1 d-flex mb-1'>
            <KTSVG
              path={icon}
              className='svg-icon-1 svg-icon-gray-500 me-1'
            />
          </span>
          <h3 className='card-title fw-bolder fs-3 text-dark all-center '>
            {title}
          </h3>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-0'>
        <div className="row">
          <div className="col-3">
            {/* begin::Item */}
            <div className='d-flex align-items-center bg-light-warning rounded p-5'>
              {/* begin::Icon */}
              <span className='svg-icon svg-icon-warning me-5'>
                <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
              </span>
              {/* end::Icon */}
              {/* begin::Title */}
              <div className='flex-grow-1 me-2'>
                <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-6'>
                  Número total de empreendimentos:
                </a>
              </div>
              {/* end::Title */}
              {/* begin::Lable */}
              <span className='fw-bolder fs-2 text-warning py-1'>{data.GetEmpreendimentosRelatorio.totalEmpreendimentos} </span>
              {/* end::Lable */}
            </div>
            {/* end::Item */}
          </div>
          <div className="col-3">
            {/* begin::Item */}
            <div className='d-flex align-items-center bg-light-success rounded p-5'>
              {/* begin::Icon */}
              <span className='svg-icon svg-icon-success me-5'>
                <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
              </span>
              {/* end::Icon */}
              {/* begin::Title */}
              <div className='flex-grow-1 me-2'>
                <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-6'>
                  Área total de lotes:
                </a>
              </div>
              {/* end::Title */}
              {/* begin::Lable */}
              <span className='fw-bold fs-2 text-success py-1'>{formatNumber(data.GetEmpreendimentosRelatorio.areaTotalTerreno,'decimal')}  m²</span>
              {/* end::Lable */}
            </div>
            {/* end::Item */}
          </div>
          <div className="col-3">
            {/* begin::Item */}
            <div className='d-flex align-items-center bg-light-danger rounded p-5'>
              {/* begin::Icon */}
              <span className='svg-icon svg-icon-danger me-5'>
                <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
              </span>
              {/* end::Icon */}
              {/* begin::Title */}
              <div className='flex-grow-1 me-2'>
                <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                  Área privativa total:
                </a>
              </div>
              {/* end::Title */}
              {/* begin::Lable */}
              <span className='fw-bold  fs-2 text-danger py-1'>{formatNumber(data.GetEmpreendimentosRelatorio.areaTotalPrivativa, 'decimal')} m²</span>
              {/* end::Lable */}
            </div>
            {/* end::Item */}
          </div>
          <div className="col-3">
            {/* begin::Item */}
            <div className='d-flex align-items-center bg-light-info rounded p-5'>
              {/* begin::Icon */}
              <span className='svg-icon svg-icon-info me-5'>
                <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
              </span>
              {/* end::Icon */}
              {/* begin::Title */}
              <div className='flex-grow-1 me-2'>
                <a className='fw-bold text-gray-800 text-hover-primary fs-6'>
                  Número total de lotes:
                </a>
              </div>
              {/* end::Title */}
              {/* begin::Lable */}
              <span className='fw-bold  fs-2 text-info py-1'> {formatNumber(data.GetEmpreendimentosRelatorio.totalTerrenos,'inteiro')} </span>

              {/* end::Lable */}
            </div>
            {/* end::Item */}
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-4">
            {/* begin::Item */}
            <div className='d-flex align-items-center bg-light-success rounded p-5'>
              {/* begin::Icon */}
              <span className='svg-icon svg-icon-success me-5'>
                <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
              </span>
              {/* end::Icon */}
              {/* begin::Title */}
              <div className='flex-grow-1 me-2'>
                <a href='#' className='fw-bold text-gray-800 text-hover-primary fs-6'>
                Média de lotes por empreendimento: 
                </a>
              </div>
              {/* end::Title */}
              {/* begin::Lable */}
              <span className='fw-bold fs-2 text-success py-1'> { Math.ceil(data.GetEmpreendimentosRelatorio.totalMediaTerrenosEmpreendimentos)}</span>
              {/* end::Lable */}
            </div>
            {/* end::Item */}
          </div>
          <div className="col-4">
            {/* begin::Item */}
            <div className='d-flex align-items-center bg-light-danger rounded p-5'>
              {/* begin::Icon */}
              <span className='svg-icon svg-icon-danger me-5'>
                <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
              </span>
              {/* end::Icon */}
              {/* begin::Title */}
              <div className='flex-grow-1 me-2'>
                <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                  VGV Médio (R$ milhão):
                </a>
              </div>
              {/* end::Title */}
              {/* begin::Lable */}
              <span className='fw-bold  fs-2 text-danger py-1'>{formatNumber(data.GetEmpreendimentosRelatorio.vgvMedio, 'currency') }</span>
              {/* end::Lable */}
            </div>
            {/* end::Item */}
          </div>
          <div className="col-4">
            {/* begin::Item */}
            <div className='d-flex align-items-center bg-light-info rounded p-5'>
              {/* begin::Icon */}
              <span className='svg-icon svg-icon-info me-5'>
                <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
              </span>
              {/* end::Icon */}
              {/* begin::Title */}
              <div className='flex-grow-1 me-2'>
                <a className='fw-bold text-gray-800 text-hover-primary fs-6'>
                  Total VGV (R$ milhão):
                  <span className='fw-bold text-info py-1'></span>
                </a>
              </div>
              {/* end::Title */}
              {/* begin::Lable */}
              {/* begin::Lable */}
              <span className='fw-bold  fs-2 text-info py-1'>{ formatNumber(data.GetEmpreendimentosRelatorio.vgv, 'currency') }</span>
              {/* end::Lable */}
              {/* end::Lable */}
            </div>
            {/* end::Item */}
          </div>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export { InfosRelatorios }
