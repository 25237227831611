/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Property} from '../partials/types'
import {CurrencyFormat, DateTimToView, formatNumber} from '../../../components/util'

type Props = {
  className: string
  color: string
  adress: string
  result: Property
}

const Header: React.FC<Props> = ({className, color, result, adress}) => {
  const adressString = adress
  const adressArray = adressString.split('-')
  const InfosLoteSplited = adressArray.map((adress) => {
    return adress.trim()
  })

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Header */}
        <div className=''>
          {/* end::Heading */}
          <div className='w-100 all-center-between row'>
            <div className='col-6'>
              <span className='fs-3'> {result ? result[0].address : 'Não informado'}</span>
            </div>
            <div className='col-6'>
              <div className='row text-center d-flex all-center-space'>
                <div className=' me-4 col-12 pb-3'>
                  <span className='fs-3  fw-bold text-center  '>
                    Preço médio m²{' '}
                    <span className='fts-2 text-success'>
                      {result ? formatNumber(result[0].price,'currency' ) : '0'}
                    </span>
                  </span>
                </div>
                <div className='d-flex flex-row me-4 col-12 '>
                  <span className=' fw-bold text-success  col-12'>
                    
                    <span className='fts-2 text-success'>{result ?  formatNumber(result[0].price_m2,'currency' ) : '0'}</span>
                    <span className='fs-3  text-black me-2'>/m²</span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className='row mt-10'>
            <div className='d-flex '>
              <h2 className='title-d mt-2'>Informações Relevantes</h2>
            </div>
            <span className='bullet bullet-horizontal h-1px w-100 bg-grey mt-3 mb-5'></span>
            <div className='col-6 bg-body'>
              <div className='row all-center-space'>
                <div className='col-12'>
                  {/* begin::Item */}
                  <div className='d-flex all-center-between '>
                    {/* begin::Symbol */}
                    <div className='symbol symbol-45px w-40px me-5'>
                      <span className='symbol-label bgBody'>
                        <KTSVG
                          path='/media/icons/duotune/general/gen014.svg'
                          className='svg-icon-1'
                        />
                      </span>
                    </div>
                    {/* end::Symbol */}
                    {/* begin::Description */}
                    <div className='d-flex align-items-center flex-wrap w-100'>
                      {/* begin::Title */}

                      <div className='pe-3 flex-grow-1'>
                        <span className='fw-bolder fs-4 text-gray-800 me-2'>Lançado em :</span>
                      </div>
                      {/* end::Title */}
                      {/* begin::Label */}
                      <div className='d-flex all-center'>
                        <div className='fw-bolder fs-4 text-gray-800 pe-1'>
                          {result ? DateTimToView(result[0].launch_date) : 'Não informado'}
                        </div>
                      </div>
                      {/* end::Label */}
                    </div>
                    {/* end::Description */}
                  </div>
                  <div className='d-flex align-items-center'>
                    {/* begin::Symbol */}
                    <div className='symbol symbol-45px w-40px me-5'>
                      <span className='symbol-label bgBody'>
                        <KTSVG
                          path='/media/icons/duotune/general/gen014.svg'
                          className='svg-icon-1'
                        />
                      </span>
                    </div>
                    {/* end::Symbol */}
                    {/* begin::Description */}
                    <div className='d-flex align-items-center flex-wrap w-100'>
                      {/* begin::Title */}
                      <div className='mb-1 pe-3 flex-grow-1'>
                        <strong className='fs-4 me-2'>Data Ultima Atualização Órulo: </strong>
                      </div>
                      {/* end::Title */}
                      {/* begin::Label */}
                      <div className='d-flex align-items-center'>
                        <span className='fs-4 fw-bolder text-gray-800'>
                          <div className='fw-bolder fs-4 text-gray-800 pe-1'>
                            {result ? DateTimToView(result[0].date_last_price) : 'Não informado'}
                          </div>
                        </span>
                      </div>
                      {/* end::Label */}
                    </div>
                    {/* end::Description */}
                  </div>
                  {/* end::Item */}
                  {/* begin::Item */}
                  <div className='d-flex align-items-center'>
                    {/* begin::Symbol */}
                    <div className='symbol symbol-45px w-40px me-5'>
                      <span className='symbol-label bgBody'>
                        <KTSVG
                          path='/media/icons/duotune/general/gen008.svg'
                          className='svg-icon-1'
                        />
                      </span>
                    </div>
                    {/* end::Symbol */}
                    {/* begin::Description */}
                    <div className='d-flex align-items-center flex-wrap w-100'>
                      {/* begin::Title */}
                      <div className='mb-1 pe-3 flex-grow-1'>
                        <strong className='fs-4 me-2'>Total de unidades:</strong>
                      </div>
                      {/* end::Title */}
                      {/* begin::Label */}
                      <div className='d-flex align-items-center'>
                        <span className='fs-4 fw-bolder text-gray-800'>
                          <div className='fw-bolder fs-4 text-gray-800 pe-1'>
                            {result ? result[0].total_units : 'Não informado'}
                          </div>
                        </span>
                      </div>
                      {/* end::Label */}
                    </div>
                    {/* end::Description */}
                  </div>
                  {/* end::Item */}
                  {/* begin::Item */}
                  <div className='d-flex align-items-center'>
                    {/* begin::Symbol */}
                    <div className='symbol symbol-45px w-40px me-5'>
                      <span className='symbol-label bgBody'>
                        <KTSVG
                          path='/media/icons/duotune/general/gen010.svg'
                          className='svg-icon-1'
                        />
                      </span>
                    </div>
                    {/* end::Symbol */}
                    {/* begin::Description */}
                    <div className='d-flex align-items-center flex-wrap w-100'>
                      {/* begin::Title */}
                      <div className='mb-1 pe-3 flex-grow-1'>
                        <strong className='fs-4 me-2'>Unidades vendidas </strong>
                      </div>
                      {/* end::Title */}
                      {/* begin::Label */}
                      <div className='d-flex align-items-center'>
                        <span className='fs-4 fw-bolder text-gray-800'>
                          {result ? result[0].total_units - result[0].stock : 'Não informado'}
                        </span>
                      </div>
                      {/* end::Label */}
                    </div>
                    {/* end::Description */}
                  </div>
                  {/* end::Item */}
                  {/* begin::Item */}
                  <div className='d-flex align-items-center'>
                    {/* begin::Symbol */}
                    <div className='symbol symbol-45px w-40px me-5'>
                      <span className='symbol-label bgBody'>
                        <KTSVG
                          path='/media/icons/duotune/general/gen010.svg'
                          className='svg-icon-1'
                        />
                      </span>
                    </div>
                    {/* end::Symbol */}
                    {/* begin::Description */}
                    <div className='d-flex align-items-center flex-wrap w-100'>
                      {/* begin::Title */}
                      <div className='mb-1 pe-3 flex-grow-1'>
                        <strong className='fs-4 me-2'>Unidades Restantes </strong>
                      </div>
                      {/* end::Title */}
                      {/* begin::Label */}
                      <div className='d-flex align-items-center'>
                        <span className='fs-4 fw-bolder text-gray-800'>
                          {result
                            ? result[0].total_units - (result[0].total_units - result[0].stock)
                            : 'Não informado'}
                        </span>
                      </div>
                      {/* end::Label */}
                    </div>
                    {/* end::Description */}
                  </div>
                  {/* end::Item */}
                  {/* begin::Item */}
                  <div className='d-flex align-items-center'>
                    {/* begin::Symbol */}
                    <div className='symbol symbol-45px w-40px me-5'>
                      <span className='symbol-label bgBody'>
                        <KTSVG
                          path='/media/icons/duotune/general/gen010.svg'
                          className='svg-icon-1'
                        />
                      </span>
                    </div>
                    {/* end::Symbol */}
                    {/* begin::Description */}
                    <div className='d-flex align-items-center flex-wrap w-100'>
                      {/* begin::Title */}
                      <div className='mb-1 pe-3 flex-grow-1'>
                        <strong className='fs-4 me-2'>% Vendas</strong>
                      </div>
                      {/* end::Title */}
                      {/* begin::Label */}
                      <div className='d-flex align-items-center'>
                        <span className='fs-4 fw-bolder text-gray-800'>
                          {result
                            ? (
                                ((result[0].total_units - result[0].stock) /
                                  result[0].total_units) *
                                100
                              ).toFixed(0) + '%'
                            : 'Não informado'}
                        </span>
                      </div>
                      {/* end::Label */}
                    </div>
                    {/* end::Description */}
                  </div>
                  {/* end::Item */}
                </div>
              </div>
            </div>
            <div className='col-6 bg-body'>
              <div className='row all-center-space'>
                <div className='col-12'>
                  <div className='summary-list'>
                    <ul className='p-0'>
                      <div className='d-flex'>
                        <span className='bullet bullet-vertical h-20px w-2px bg-success me-2'></span>
                        <li className='d-flex w-100 justify-content-between py-1'>
                          <span className='fs-6'>ID:</span>
                          <span className='fs-5 fw-bold text-gray-800'>
                            {result ? result[0].id : '-'}
                          </span>
                        </li>
                      </div>
                      <div className='d-flex all-start-center'>
                        <span className='bullet bullet-vertical h-20px w-2px bg-success me-2'></span>
                        <li className='d-flex  w-100 justify-content-between py-1'>
                          <span className='fs-6'>Incorporadora:</span>
                          <span className='fs-5 fw-bold text-gray-800'>
                            {result ? result[0].developer_name : '-'}
                          </span>
                        </li>
                      </div>
                      <div className='d-flex all-start-center'>
                        <span className='bullet bullet-vertical h-20px w-2px bg-success me-2'></span>
                        <li className='d-flex  w-100 justify-content-between py-1'>
                          <span className='fs-6'>Localização:</span>
                          <div className='col-8 text-right'>
                            <span className='fs-5 fw-bold text-gray-800'>
                              {InfosLoteSplited[0]}
                            </span>
                          </div>
                        </li>
                      </div>
                      <div className='d-flex all-start-center'>
                        <span className='bullet bullet-vertical h-20px w-2px bg-success me-2'></span>
                        <li className='d-flex  w-100 justify-content-between py-1'>
                          <span className='fs-6'>Bairro:</span>
                          <span className='fs-5 fw-bold text-gray-800'>{InfosLoteSplited[1]}</span>
                        </li>
                      </div>
                      {/* <div className='d-flex all-start-center'>
                        <span className='bullet bullet-vertical h-20px w-2px bg-success me-2'></span>
                        <li className='d-flex  w-100 justify-content-between py-1'>
                          <span className='fs-6'>Cidade/Estado:</span>
                          <span className='fs-5 fw-bold text-gray-800'>{`${InfosLoteSplited[2]}/${InfosLoteSplited[3]}`}</span>
                        </li>
                      </div> */}
                      {/* <div className='d-flex all-start-center'>
                        <span className='bullet bullet-vertical h-20px w-2px bg-success me-2'></span>
                        <li className='d-flex  w-100 justify-content-between py-1'>
                          <span className='fs-6'>Tipo:</span>
                          <span className='fs-5 fw-bold text-gray-800'>
                            {' '}
                            {result ? result[0].finality : '-'}
                          </span>
                        </li>
                      </div> */}
                      <div className='d-flex all-start-center'>
                        <span className='bullet bullet-vertical h-20px w-2px bg-success me-2'></span>
                        <li className='d-flex  w-100 justify-content-between py-1'>
                          <span className='fs-6'>Situação</span>
                          <span className='fs-5 fw-bold text-gray-800'>
                            {result ? result[0].situacao : '-'}
                          </span>
                        </li>
                      </div>
                      <div className='d-flex all-start-center'>
                        <span className='bullet bullet-vertical h-20px w-2px bg-success me-2'></span>
                        <li className='d-flex  w-100 justify-content-between py-1'>
                          <span className='fs-6'>Apartamentos por andar:</span>
                          <span className='fs-5 fw-bold text-gray-800'>
                            {result ? result[0].apts_per_floor : ''}
                          </span>
                        </li>
                      </div>
                      <div className='d-flex all-start-center'>
                        <span className='bullet bullet-vertical h-20px w-2px bg-success me-2'></span>
                        <li className='d-flex  w-100 justify-content-between py-1'>
                          <span className='fs-6'>Total de Unidades:</span>
                          <span className='fs-5 fw-bold text-gray-800'>
                            {result ? result[0].total_units : ''}
                          </span>
                        </li>
                      </div>
                      {/* <div className='d-flex all-start-center'>
                        <span className='bullet bullet-vertical h-20px w-2px bg-success me-2'></span>
                        <li className='d-flex  w-100 justify-content-between py-1'>
                          <span className='fs-6'>Área(m²):</span>
                          <span className='fs-5 fw-bold text-gray-800'>
                            {result ? result[0].min_area : ''}/{result ? result[0].max_area : ''}m²
                          </span>
                        </li>
                      </div>
                      <div className='d-flex all-start-center'>
                        <span className='bullet bullet-vertical h-20px w-2px bg-success me-2'></span>
                        <li className='d-flex  w-100 justify-content-between py-1'>
                          <span className='fs-6'>Suíte(s):</span>
                          <span className='fs-5 fw-bold text-gray-800'>
                            {result ? result[0].min_suites : ''}/
                            {result ? result[0].max_suites : ''}
                          </span>
                        </li>
                      </div>
                      <div className='d-flex all-start-center'>
                        <span className='bullet bullet-vertical h-20px w-2px bg-success me-2'></span>
                        <li className='d-flex  w-100 justify-content-between py-1'>
                          <span className='fs-6'>Banheiro(s):</span>
                          <span className='fs-5 fw-bold text-gray-800'>
                            {result ? result[0].min_bathrooms : ''}/
                            {result ? result[0].max_bathrooms : ''}
                          </span>
                        </li>
                      </div>
                      <div className='d-flex all-start-center'>
                        <span className='bullet bullet-vertical h-20px w-2px bg-success me-2'></span>
                        <li className='d-flex  w-100 justify-content-between py-1'>
                          <span className='fs-6'>Vaga(s):</span>
                          <span className='fs-5 fw-bold text-gray-800'>
                            {result ? result[0].min_parking : ''}/
                            {result ? result[0].max_parking : ''}
                          </span>
                        </li>
                      </div> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end::Items */}
        </div>
      </div>
    </div>
  )
}

export {Header}
