import {useEffect, useState} from 'react'
import Select from 'react-select'
import {QueryGetEmpreendimentoTags} from '../../../../graphql'

export function GetEmpreendimentosTagsChosen({defaultValue, onChange}) {
  let itensArray = []
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue] = useState([])

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  const {data} = QueryGetEmpreendimentoTags()

  if (!data) {
    return (
      <>
        <div className='form-group'>
          <label className='col-form-label'>Empreendimentos:</label>
          <Select
            options={itensArray}
            onChange={(e) => {
              setValue(e.value)
            }}
            value={itensArray.filter((e) => e.value)[0]}
            name='id_corretor'
            placeholder='Empreendimentos Tags'
          />
        </div>
      </>
    )
  }
  itensArray.push({value: '', label: '--selecione--'})
  data.GetEmpreendimentoTags.map((Options) => {
    itensArray.push({value: Options.tag, label: Options.tag})
    return []
  })

  return (
    <Select
      options={itensArray}
      value={itensArray.filter((item) => item.label === defaultValue) || ''}
      onChange={(e) => {
        onChange(e.label)
        setValue(e.label)
      }}
      name='id_tag'
      placeholder='Empreendimentos Tags'
    />
  )
}
