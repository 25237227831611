import { useMutation, useQuery } from "@apollo/client"
import { TypesGetEquipesResponse, TypesPutEquipeResponse, TypesSetEquipeResponse, TypesDeleteEquipeResponse, TypesGetEquipeByIDResponse } from "../type/equipes"
import { GET_EQUIPES, GET_EQUIPE_BY_ID } from "../query/equipes"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { PUT_EQUIPE, SET_EQUIPE, DELETE_EQUIPE } from "../mutations/equipes"
import { putEquipeFormData, putEquipeFormSchema, setEquipeFormData, setEquipeFormSchema } from "../formValidations/Equipes"

interface Props {
  variables: any
}

export function QueryGetEquipes({ variables }: Props) {
  const { data, loading, error } = useQuery<TypesGetEquipesResponse>(GET_EQUIPES, {
    variables: { ...variables },
  })
  return { data, loading, error }
}

export function QueryGetEquipeByID({ variables }: Props) {
  const { data, loading, error } = useQuery<TypesGetEquipeByIDResponse>(GET_EQUIPE_BY_ID, {
    variables: { ...variables },
  })
  return { data, loading, error }
}

export function MutationSetEquipe() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<setEquipeFormData>({
    resolver: zodResolver(setEquipeFormSchema),
  })

  console.log(errors)

  const [setEquipeBody, { error, loading, data: DataSetEquipe }] =
    useMutation<TypesSetEquipeResponse>(SET_EQUIPE, {
      refetchQueries: [GET_EQUIPES],
    })

  async function FormEquipe(data: setEquipeFormData) {
    await setEquipeBody({
      variables: {
        equipe: {
          nome: data.nome,
        }
      }
    })
  }

  return {
    register,
    handleSubmit,
    FormEquipe,
    loading,
    errors,
    error,
    DataSetEquipe,
  }
}

export function MutationPutEquipe() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<putEquipeFormData>({
    resolver: zodResolver(putEquipeFormSchema),
  })

  console.log(errors)

  const [setEquipeBody, { error, loading, data: DataPutEquipe }] =
    useMutation<TypesPutEquipeResponse>(PUT_EQUIPE, {
      refetchQueries: [GET_EQUIPES],
    })

  async function FormEquipe(data: putEquipeFormData) {
    await setEquipeBody({
      variables: {
        equipe: {
          nome: data.nome,
        },
        putEquipeId: data.id
      }
    })
  }

  return {
    register,
    handleSubmit,
    FormEquipe,
    loading,
    errors,
    error,
    DataPutEquipe,
  }
}

export function MutationDeleteEquipe() {
  const [deleteEquipe, { data, error, loading }] =
    useMutation<TypesDeleteEquipeResponse>(DELETE_EQUIPE)


  async function HandleDeleteEquipe({ variables }: Props) {
    try {
      const result = await deleteEquipe({
        variables: { ...variables },
        refetchQueries: [GET_EQUIPES],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return { HandleDeleteEquipe, loading, error, data }
}