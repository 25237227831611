import { Modal } from 'react-bootstrap'
import {
  MutationPutEquipe,
  QueryGetEquipeByID,
} from '../../../../graphql/services/Equipes'
import Swal from 'sweetalert2'
import { ErrorMessageComponent } from '../../../../backoffice/components/UI/ErrorMessage'
import { Loader } from '../../../../components/UI/Loader/Loader'
import { MutationSetLogAcessoRecurso } from '../../../../graphql/services/LogAcesso'
import { useState } from 'react'


interface situacaoTerrenoProps {
  id: number
  show: boolean
  closeModal: any
}

export function EditForm({ id, show, closeModal }: situacaoTerrenoProps) {

  let { data, loading: LoadingGet } = QueryGetEquipeByID({
    variables: { getEquipeByIdId: id },
  })

  const {
    loading,
    handleSubmit,
    register,
    errors,
    FormEquipe
  } = MutationPutEquipe()

  const { FormSetLogAcessoRecurso } = MutationSetLogAcessoRecurso()

  const onSubmit = async (data) => {
    FormEquipe(data).then((result) => {
      FormSetLogAcessoRecurso({ ferramenta: 'cadastro', funcionalidade: 'equipes', action: 'update', url: '/cadastro/equipes' });
      Swal.fire('Sucesso!', 'Dados atualizados com sucesso.', 'success')
      closeModal()
    }).catch((error) => {
      Swal.fire('Erro!', 'Ocorreu um erro durante o processo.', 'error')
    })
  }

  // Tratativa de loading
  if (loading || LoadingGet) {
    return (
      <Loader text={'caregando...'}></Loader>
    )
  }


  return (
    <>
      <Modal centered animation show={show} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Equipe</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              className='form-control form-control-solid'
              type='number'
              hidden={true}
              defaultValue={data ? data.GetEquipeById.id : ''}
              {...register('id', { required: true, valueAsNumber:true })}
            />
            <div className='row'>
              <div className='col-md-12'>
                <div className='mb-6'>
                  <label htmlFor='nome' className='required form-label'>
                    Nome:
                  </label>
                  <input
                    className='form-control form-control-solid'
                    placeholder='Nome da Situação'
                    defaultValue={data ? data.GetEquipeById.nome : ''}
                    {...register('nome', {
                      pattern: /^[A-Za-z]+$/i,
                    })}
                  />
                  {errors.nome && <ErrorMessageComponent message={errors.nome?.message} />}
                </div>
              </div>

              <div className='col-md-12 py-6 d-flex' style={{ justifyContent: 'flex-end' }}>
                <button
                  className='btn btnSecondary  pull-right '
                  type='button'
                  onClick={() => closeModal()}
                >
                  Fechar
                </button>
                <button disabled={loading} type='submit' className='btn btn-success mx-2'>
                  Salvar
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
