import { z } from "zod"

export const setEquipeFormSchema = z.object({
    id: z.string(),
    nome: z.string()
        .nonempty('O nome do corretor é obrigatório!'),
    email: z.string()
        .nonempty('Email do corretor é obrigatório!'),
    telefone: z.string(),
    empresa: z.string()
})

export type setEquipeFormData = z.infer<typeof setEquipeFormSchema>

export const putEquipeFormSchema = z.object({
    id: z.string(),
    nome: z.string()
        .nonempty('O nome do corretor é obrigatório!'),
    email: z.string()
        .nonempty('Email do corretor é obrigatório!'),
    telefone: z.string(),
    empresa: z.string()
})

export type putEquipeFormData = z.infer<typeof putEquipeFormSchema>