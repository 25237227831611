import {FC, useContext} from 'react'
import {AvmContext} from './context/AvmContext'
import {AutocompleteGoogle} from '../../Autocomplete/Autocomplete'
import {APiUrbit} from '../../../../services/api/apiUrbit'
import {PathToCoordinates} from '../../MapView'
import {Marker} from '@react-google-maps/api'
import {GoogleMaps} from '../../GoogleMaps'
import {GetAcessoUsuarioProduto} from '../../../../utils/PermissoesAcesso'
import {MensagemAlertAcesso} from '../../MensagemAlertAcesso'

const api = new APiUrbit()

type Props = {}

export const AvmPasso0: FC<Props> = () => {
  const {endereco, setEndereco} = useContext(AvmContext)

  async function onchangeaddres(result) {
    let endereco = {
      latitude: result.latitude,
      longitude: result.longitude,
      endereco_completo: result.endereco_completo,
      logradouro: result.logradouro,
      numero: result.numero,
      bairro: result.bairro,
      cidade: result.cidade,
      estado: result.estado,
      cep: result.cep,
    }

    const municipio = await api.get(`municipios/${result.longitude}/${result.latitude}`)
    console.log(municipio)

    setEndereco({...endereco})
  }

  function onChangeMap(geom, lote) {
    //TODO  Reverse geocoder
    if (geom) {
      const geometria = PathToCoordinates(geom)

      let result = {}
      // const multiPolygon = {
      //   type: 'MultiPolygon',
      //   coordinates: [],
      // }
      // if (document.getElementById('marcacao')) {

      //   multiPolygon.coordinates.push([geometria])
      //   document.getElementById('marcacao')['value'] = JSON.stringify(multiPolygon)
      //   setValue('marcacao', JSON.stringify(multiPolygon))
      // }

      // let endereco = {
      //   latitude: result.latitude,
      //   longitude: result.longitude,
      //   endereco_completo: result.endereco_completo,
      //   logradouro: result.logradouro,
      //   numero: result.numero,
      //   bairro: result.bairro,
      //   cidade: result.cidade,
      //   estado: result.estado,
      //   cep: result.cep,
      // }
    }
  }
  // const municipioAvariable = [5351, 3661]
  // useEffect(()=>{
  //   async function getMunicipio(lat, lng) {
  //     const api = new APiUrbit()
  //     const municipio = await api.get(`municipios/${lng}/${lat}`)

  //     if ( municipioAvariable.indexOf(municipio.id) === -1 ) {
  //       setAvariable(false)
  //      }else{
  //       setAvariable(true)
  //      }
  //   }

  //   if (municipio && Object.entries(municipio).length > 0  ){
  //     if ( municipioAvariable.indexOf(municipio.id) === -1 ) {
  //       setAvariable(false)
  //     }else{
  //       setAvariable(true)
  //     }
  //   }else{
  //     getMunicipio(lat,lng)
  //   }

  // },[lat, lng, municipio, municipioAvariable])

  // async function onChangeMarker(data){
  //   const lote = await getLote(data.latLng.lng(),data.latLng.lat())
  //   let poly = {}
  //   if (lote.itens.length > 0 ){
  //     poly = JSON.parse(lote.itens[0].geom)
  //   }
  //   let path = CoordinatesToPath(poly['coordinates'][0][0])
  //   // setPoligono(path)
  //   setPoints([[data.latLng.lat(), data.latLng.lng()]])
  //   onChange(path,lote)
  // }
  const permissao = GetAcessoUsuarioProduto(21)

  return (
    <>
      {!permissao ? (
        <div className='alert alert-warning'>
          <h3 className='pt-2 ps-2'>
            Você não tem este produto contratado, por favor entre em contato no email:{' '}
            <a href='mailto:contato@urbit.com.br?subject=Sobre Avaliação de Imóveis&body=Gostaria de informações sobre o produto de avaliação de imóveis.'>
              contato@urbit.com.br
            </a>
          </h3>
          <MensagemAlertAcesso mensagem='Somente para assinantes do Produto - Urbit Pricing.'>
            {' '}
          </MensagemAlertAcesso>
        </div>
      ) : (
        <>
          <div className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <label className='col-form-label'>Endereço</label>
                <AutocompleteGoogle
                  onChangeAddress={onchangeaddres}
                  endereco={endereco.endereco_completo}
                ></AutocompleteGoogle>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='card-body p-6 pt-5'>
              <div className='col-md-12'>
                <div className='row '>
                  <h4>Arraste o PIN para marcar um novo local</h4>
                </div>
                <div className='row '>
                  <GoogleMaps
                    latLng={{
                      lat: endereco.latitude,
                      lng: endereco.longitude,
                    }}
                  >
                    {endereco.latitude ? (
                      <Marker
                        key={'marker'}
                        label={endereco.logradouro}
                        position={{lat: endereco.latitude, lng: endereco.longitude}}
                        draggable
                        onDragEnd={(data) => {
                          // onChangeMarker(data)
                        }}
                      ></Marker>
                    ) : (
                      <></>
                    )}
                  </GoogleMaps>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
