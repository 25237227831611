import { gql } from "@apollo/client";

export const SET_EMPREENDIMENTO_TEMPERATURA = gql`
mutation SetEmpreendimentoTemperatura($temperatura: Temperatura!) {
  SetEmpreendimentoTemperatura(Temperatura: $temperatura) {
    id
  }
}
`

export const PUT_EMPREENDIMENTO_TEMPERATURA = gql`
mutation PutEmpreendimentoTemperatura($putEmpreendimentoTemperaturaId: Float!, $temperatura: Temperatura!) {
  PutEmpreendimentoTemperatura(id: $putEmpreendimentoTemperaturaId, Temperatura: $temperatura) {
    id
  }
}
`

export const DELETE_EMPREENDIMENTO_TEMPERATURA = gql`
mutation DeleteEmpreendimentoTemperatura($deleteEmpreendimentoTemperaturaId: Float!) {
  DeleteEmpreendimentoTemperatura(id: $deleteEmpreendimentoTemperaturaId) {
    id
  }
}
`
