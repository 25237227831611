import { z } from "zod"

export const setEquipeFormSchema = z.object({
    nome: z.string()
        .nonempty('O nome da equipe é obrigatório!'),
})

export type setEquipeFormData = z.infer<typeof setEquipeFormSchema>

export const putEquipeFormSchema = z.object({
    nome: z.string()
        .nonempty('O nome da equipe é obrigatório!'),
    id: z.number()
})

export type putEquipeFormData = z.infer<typeof putEquipeFormSchema>
