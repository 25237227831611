import {Text, View} from '@react-pdf/renderer'
import {styles} from '../style'
import {Dicionario} from '../../../../utils/Dicionario'
import {formatPhone} from '../../../util'

export const RelatedPeoplePhones = ({data, estrutura}) => {
  let dateReturn = null

  if (typeof data['Result'] !== 'undefined') {
    dateReturn = data['Result'][0]['RelatedPeoplePhones']
  } else {
    if (typeof data[0]['RelatedPeoplePhones'] !== 'undefined'){
      dateReturn = data[0]['RelatedPeoplePhones']
    }
  }

  return dateReturn ? (
    <>
      <View style={[styles.table, {paddingTop: 15}]}>
        <View style={[styles.row, {paddingTop: 15, paddingBottom: 10}]}>
          <Text style={[styles.subtitulo]}>Pessoas Relacionadas Telefones</Text>
        </View>
        <View style={[styles.row, styles.bold, styles.header]}>
          {/* {estrutura['colunsItens'].map((column, index) => { */}
          {/* return ( */}
          <Text style={styles.rowDefault}>Telefone</Text>
          <Text style={styles.rowDefault}>Grau Parentesco</Text>
          {/* ) */}
          {/* })} */}
        </View>
      </View>

      <View>
        {dateReturn?.map((item, index) => {
          return (
            <View key={index} style={styles.row} wrap={false}>
              {/* {estrutura['colunsItens'].map((column, index2) => {
                return ( */}
              <Text style={styles.rowDefault}>{`(${item['AreaCode']}) ${item['Number']}`}</Text>
              <Text style={styles.rowDefault}>
                {Dicionario.translate(item['RelationshipType'])}
              </Text>
              {/* )
              })} */}
            </View>
          )
        })}
      </View>
    </>
  ) : (
    <></>
  )
}
