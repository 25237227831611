import { z } from "zod"

// Esquema para criar ou ajustar a situação de um empreendimento
export const setEmpreendimentoSituacaoFormSchema = z.object({
  nome: z.string().nonempty('O nome da situação é obrigatório!'),
  cor: z.string().nullable(),
});

export type setEmpreendimentoSituacaoFormData = z.infer<typeof setEmpreendimentoSituacaoFormSchema>;

// Esquema para atualizar a situação de um empreendimento
export const putEmpreendimentoSituacaoFormSchema = z.object({
  id: z.number(),
  nome: z.string().nonempty('O nome da situação é obrigatório!'),
  cor: z.string().nullable()
});

export type putEmpreendimentoSituacaoFormData = z.infer<typeof putEmpreendimentoSituacaoFormSchema>;
