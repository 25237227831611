import {Text, View} from '@react-pdf/renderer'
import {styles} from '../style'

export const PhonesExtended = ({data, estrutura}) => {
  let dateReturn = null

  if (typeof data['Result'] !== 'undefined') {
    dateReturn = data['Result'][0]['ExtendedPhones']['Phones']
  } else {
    
    if (typeof data[0]['ExtendedPhones'] !== 'undefined'){
      dateReturn = data[0]['ExtendedPhones']['Phones']
    }
  }

  return dateReturn ? (
    <>
      <View>
        {dateReturn?.map((item, index) => {
          return (
            <View key={index}>
              <Text style={[styles.space, styles.h4, styles.bold]}>
                Telefone: ({item.AreaCode}) {item['Number']} ({item.Type})
              </Text>
            </View>
          )
        })}
      </View>
    </>
  ) : (
    <></>
  )
}
