import {BiRuler} from 'react-icons/bi'
import {Layout} from '../../Layout'
import {MapControll} from '../../../../../../../store/mapControll'
import {Meassure} from './Measure'

export const DrawMeasure: React.FC = () => {

  const meassure = new Meassure()

  function clearActiveAction() {
    document.querySelectorAll('.btn-action-map').forEach(function (x) {
      x.classList.remove('active')
    })
  }

  function onClickMeasure(e) {
    Layout.setShowTerritorioMenu(false)
    Layout.setShowContextMenu(false)
    Layout.setShowGeometryMenuArea(false)
    Layout.setShowGeometryMenu(false)

    if (!e.currentTarget.classList.contains('active')) {
      clearActiveAction()
      e.currentTarget.classList.add('active')
      meassure.setActive(true)
      MapControll.setMapActionClick('meassure', (event, callback) => {
        meassure.controllClick(event, callback)
      })
    } else {
      meassure.setActive(false)
      meassure.setMeassureDeactive()
      MapControll.removeMapActionClick('meassure')
      clearActiveAction()
      e.currentTarget.classList.remove('active')
    }
  }

  return (
    <>
      <button
        className='d-flex p-3 btn-action-map'
        onClick={(e) => {
          onClickMeasure(e)
        }}
      >
        <BiRuler className='fs-1'></BiRuler>
      </button>
    </>
  )
}
