import React, {useCallback, useEffect, useRef, useState} from 'react'
import {
  GoogleMap,
  Polygon,
  OverlayView,
  Marker,
  DrawingManager,
  InfoWindow,
} from '@react-google-maps/api'
import Swal from 'sweetalert2'

import * as turf from '@turf/turf'
import {marcadoresMap} from '../../../../../components/util'
import {Button, ButtonGroup} from 'react-bootstrap'
import { CreateStyles } from '../../../../explorer/components/Map/Style/MapStyles'

type PropsContextMenu = {
  latLng: any[]
  showLotes?: boolean
  showMarker?: boolean
  onClickMap?: any
  idEmpreendimento: number
  label?: string[]
}

export function CoordinatesToPath(geom) {
  let pathsCtoP = geom.map(function (el) {
    return {lat: el[1], lng: el[0]}
  })

  return pathsCtoP
}
export function CoordinatesToNumber(geom) {
  if (geom.type === 'Polygon') {
    let newGeom = {
      coordinates: [],
      type: 'Polygon',
    }

    newGeom.coordinates = geom.coordinates.map((coordinates) => {
      return coordinates.map((el) => {
        return [Number(el[0]), Number(el[1])]
      })
    })

    return newGeom
  } else {
    return geom
  }
}

export function PathToCoordinates(path) {
  let pathsToC = path.map(function (el) {
    return [el.lng, el.lat]
  })

  return pathsToC
}

export const MapView: React.FC<PropsContextMenu> = ({
  latLng,
  showLotes = true,
  showMarker = false,
  onClickMap,
  idEmpreendimento,
  label = [],
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [map, setMap] = useState(null)
  const [colorType, setColorType] = useState('situacao')
  const [center, setCenter] = useState(() => {
    let centerstart = {
      lat: null,
      lng: null,
    }
    if (latLng.length > 0) {
      if (typeof latLng[latLng.length - 1].center !== 'undefined') {
        centerstart.lat = latLng[latLng.length - 1].center.geometry.coordinates[1]
        centerstart.lng = latLng[latLng.length - 1].center.geometry.coordinates[0]
      } else {
        centerstart.lat = latLng[0]
        centerstart.lng = latLng[1]
      }
    } else {
      centerstart = {
        lat: -23.56834983242366,
        lng: -46.65739491734664,
      }
    }

    return centerstart
  })
  const [infoWindowPosition, setInfoWindowPosition] = useState<{
    position: google.maps.LatLngLiteral | null
    content: string | null
    index: number | null
  }>({
    position: null,
    content: null,
    index: null,
  })
  // const [tooltipContent, setTooltipContent] = useState<string | null>(null);

  const [paths, setPaths] = useState([])

  useEffect(() => {
    setPaths(latLng)
    return () => {
      setPaths([])
    }
  }, [latLng])

  const containerStyle = {
    height: '400px',
  }

  const options = {
    fillColor: 'lightgreen',
    fillOpacity: 0.6,
    strokeColor: 'green',
    strokeOpacity: 1,
    strokeWeight: 1,
    clickable: true,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1,
  }

  const optionsEditable = {
    fillColor: 'lightred',
    fillOpacity: 0.6,
    strokeColor: 'red',
    strokeOpacity: 1,
    strokeWeight: 1,
    clickable: true,
    draggable: true,
    editable: true,
    geodesic: false,
    zIndex: 1,
  }

  const onLoad = React.useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds(center)
    // map.fitBounds(bounds)


    CreateStyles(map)
    map.setMapTypeId('ligth')
    const imageMapType = new google.maps.ImageMapType({
      getTileUrl: function (coord, zoom) {
        // if (
        //   zoom < 17 ||
        //   zoom > 20
        //   // bounds[zoom][0][0] > coord.x ||
        //   // coord.x > bounds[zoom][0][1] ||
        //   // bounds[zoom][1][0] > coord.y ||
        //   // coord.y > bounds[zoom][1][1]
        // ) {
        //   return "";
        // }

        return [
          process.env.REACT_APP_MAP_TILE + '/tile/lotesfinal/',
          zoom,
          '/',
          coord.x,
          '/',
          coord.y,
          '.png?type=png',
        ].join('')
      },
      tileSize: new google.maps.Size(256, 256),
    })
    if (showLotes) {
      map.overlayMapTypes.push(imageMapType)
      setMap(map)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  function click(e, el) {
    if (e.stopPropagation) {
      e.stopPropagation()
    }
    if (e.preventDefault) {
      e.preventDefault()
    }
    clearSelecaoTable()
    let line = document.getElementById('row_' + el.id)
    if (line) {
      line.classList.add('active')
    }
  }

  function clearSelecaoTable() {
    document.querySelectorAll('.row-composicao').forEach(function (row) {
      row.classList.remove('active')
    })
  }

  let isLoaded = true
  const divStyle = {
    background: 'transparent',
    // border: "1px solid #ccc",
    // padding: 15
  }

  const handleMouseOver = (e: google.maps.MapMouseEvent, content, index, center) => {
    e.domEvent.stopPropagation()
    if (e.latLng) {
      // {lat:lng:lng:lng}

      setInfoWindowPosition({position: center, content: content, index: index})
    }
  }

  const handleMouseOut = () => {
    setInfoWindowPosition({position: null, content: null, index: null})
    // setTooltipContent(null);
  }
  
  function exibirPor(e,tipo) {

    setColorType(tipo)
    
    
    e.target.classList.add('active')
    
    if (tipo==='situacao'){
      
      document.getElementById('btnPorTemperatura').classList.remove('active')
    }else{
      document.getElementById('btnPorSituacao').classList.remove('active')
    }
    // console.log(e.target.parentNode.children)
    //  e.target.parentNode.children.map((e)=>{
    //    console.log(e)
    //  })
    //  console.log(tipo)

  }

  function gotoLine(index){



    var top = document.getElementById(`#line${index}`).offsetTop; //Getting Y of target element
    window.scrollTo(0, top);  
    // #line

    // var url = location.href;               //Save down the URL without hash.
    // location.href = "#"+h;                 //Go to the target element.
    // history.replaceState(null,null,url);
  }
  return isLoaded ? (
    <>
      <div className='mb-5'>
        <ButtonGroup size='sm' >
          <Button onClick={(e)=>exibirPor(e,'situacao')}  className='btn btn-success' active id="btnPorSituacao">Por Situação</Button>
          <Button onClick={(e)=>exibirPor(e,'temperatura')} className='btn btn-success' id="btnPorTemperatura">Por Temperatura</Button>
        </ButtonGroup>
      </div>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={18}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          mapId: 'abd5c4561f0720d4',
          // mapId: 'a28cbce2ae82f5e3',
          mapTypeControl: true,
          mapTypeControlOptions: {
            mapTypeIds: [
              'roadmap',
              'satellite',
              'hybrid',
              'noturno',
              'comercios_map',
              'onibus_map',
              'ligth',
              'simples',
              // 'a28cbce2ae82f5e3',
            ],
            style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
            position: google.maps.ControlPosition.LEFT_TOP,
          },
        }}
        // onClick={disableEdit}
      >
        {paths
          ? // paths.length > 2 ?
            paths.map(function (el, index) {
              let lng = el.center.geometry.coordinates[0]
              let lat = el.center.geometry.coordinates[1]
              // console.log(el)
              return (
                <div key={index}>
                  <Polygon
                    key={index}
                    // onLoad={(e) => onLoadPoligon(e, el.id)}
                    // onMouseDown={onLoadPoligonUpdate}
                    paths={el.path}
                    options={
                      el.editable
                        ? optionsEditable
                        : {
                            fillColor: colorType ==='situacao' ?  el.color || 'lightgreen' : el.colorTemperatura || 'lightgreen',
                            fillOpacity: 0.6,
                            strokeColor: '#333333ab',// colorType ==='situacao' ?  el.color || 'green' : el.colorTemperatura || 'green',
                            strokeOpacity: 1,
                            strokeWeight: 1,
                            clickable: true,
                            draggable: false,
                            editable: false,
                            geodesic: false,
                            zIndex: 1,
                          }
                    }
                    onDblClick={(e) => {
                      // dbClick(e, el, el.id)
                    }}
                    onClick={(e)=>gotoLine( index)}
                    onMouseOver={(e) =>
                      handleMouseOver(
                        e,
                        <>
                          <h3>{el.endereco}</h3>
                          <ul className='list-group'>
                            <li>
                              <h6>Empreendimento</h6>
                            </li>
                            <li>Situação: {el.empreendimentoSituacao}</li>
                            <li>Área Privativa : {el.empreendimentoAreaPrivativa}m²</li>
                            <li>Área Terreno : {el.empreendimentoAreaTerreno}m²</li>
                            <li>VGV Total: {el.empreendimentoVGV}</li>
                            <li>Temperatura: {el.empreendimentoTemperatura}</li>

                            <li className='mt-2'>
                              <h6>Lote</h6>
                            </li>
                            <li>Situação Lote: {el.terrenoSituacao}</li>

                            <li>Usuário: {el.terrenoUsuario}</li>
                          </ul>
                        </>,
                        index,
                        {lat: lat, lng: lng}
                      )
                    }
                    onMouseOut={handleMouseOut}
                  />
                  {infoWindowPosition.position && infoWindowPosition.index === index ? (
                    <InfoWindow position={infoWindowPosition.position}>
                      <div>{infoWindowPosition.content}</div>
                    </InfoWindow>
                  ) : (
                    <></>
                  )}

                </div>
              )
            })
          : <></>}
          <OverlayView position={center} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
            <div style={divStyle}>
              {/* <h1>OverlayView</h1>

              <button onClick={onClick} type="button">
                Click me
              </button> */}
            </div>
          </OverlayView>
        <></>
      </GoogleMap>
    </>
  ) : (
    <></>
  )
}
