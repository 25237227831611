import {useMutation, useQuery} from '@apollo/client'
import {
  DeleteEmpreendimentosResponse,
  EmpreendimentoClienteResponse,
  EmpreendimentoOffClienteResponse,
  EmpreendimentoResponse,
  EmpreendimentosRelatorioResponse,
  TypesPutEmpreendimentosChangeUserResponse,
  TypesPutEmpreendimentosResponse,
  TypesSetEmpreendimentosResponse,
} from '../type'
import {GET_EMPREENDIMENTO, GET_EMPREENDIMENTOS, GET_EMPREENDIMENTOS_OFF} from '../query'
import {
  FormEmpreendimentoChangeUserFormData,
  FormEmpreendimentoFormData,
  FormEmpreendimentoFormSchema,
  FormEmpreendimentoFormUpdateSchema,
  FormEmpreendimentoUpdateFormData,
} from '../formValidations'
import {zodResolver} from '@hookform/resolvers/zod'
import {useForm} from 'react-hook-form'
import {
  DELETE_EMPREENDIMENTO,
  PUT_EMPREENDIMENTO,
  SET_EMPREENDIMENTO,
  GET_EMPREENDIMENTOS_RELATORIO,
  PUT_EMPREENDIMENTO_CHANGE_USER
} from '../mutations'

interface QueryEmpreendimentosProps {
  variables: any
  skip?: any
}

export function QueryGetEmpreendimentos({variables}: QueryEmpreendimentosProps) {
  const {data, loading, error} = useQuery<EmpreendimentoClienteResponse>(GET_EMPREENDIMENTOS, {
    
    variables: {...variables},
   fetchPolicy: "network-only"

    
  },)

  return {data, loading, error}
}

export function QueryGetEmpreendimentosRelatorio({ variables }: QueryEmpreendimentosProps) {
  const { data, error, loading } = useQuery<EmpreendimentosRelatorioResponse>(GET_EMPREENDIMENTOS_RELATORIO, {
    variables: { ...variables },
  })

  return { data, error, loading }
}

export function QueryGetEmpreendimentoById({variables}: QueryEmpreendimentosProps) {
  const {data, error,loading} = useQuery<EmpreendimentoResponse>(GET_EMPREENDIMENTO, {
    variables: {...variables},
  })

  return {data, error,loading}
}

export function QueryGetOffEmpreendimentos({variables}: QueryEmpreendimentosProps) {
  const {data} = useQuery<EmpreendimentoOffClienteResponse>(GET_EMPREENDIMENTOS_OFF, {
    variables: {...variables},
  })

  return data
}

export function MutationCreateEmpreendimento() {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: {errors},
  } = useForm<FormEmpreendimentoFormData>({
    resolver: zodResolver(FormEmpreendimentoFormSchema),
  })

  const [setEmpreendimentoBody, {error, loading, data: DataSetEmpreendimento}] =
    useMutation<TypesSetEmpreendimentosResponse>(SET_EMPREENDIMENTO, {
      refetchQueries: [GET_EMPREENDIMENTOS],
    })

  async function FormEmpreendimento(data: FormEmpreendimentoFormData) {
    await setEmpreendimentoBody({
      variables: {
        empreendimentos: {
          nome: data.nome,
          endereco: data.endereco,
        },
      },
    })
  }

  return {
    register,
    handleSubmit,
    FormEmpreendimento,
    loading,
    errors,
    error,
    control,
    DataSetEmpreendimento,
    reset
  }
}

export function MutationPutEmpreendimento() {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    control,
    formState: {errors},
  } = useForm<FormEmpreendimentoUpdateFormData>({
    resolver: zodResolver(FormEmpreendimentoFormUpdateSchema),
  })

  const [setEmpreendimentoBody, {error, loading, data: DataPutEmpreendimento}] =
    useMutation<TypesPutEmpreendimentosResponse>(PUT_EMPREENDIMENTO, {
      refetchQueries: [GET_EMPREENDIMENTO,GET_EMPREENDIMENTOS],
    })

  async function FormEmpreendimento(data: FormEmpreendimentoUpdateFormData) {
   return  await setEmpreendimentoBody({
      variables: {
        empreendimentos: {...data},
      },
    })
  }

  return {
    register,
    handleSubmit,
    FormEmpreendimento,
    loading,
    errors,
    error,
    control,
    setError,
    setValue,
    getValues,
    DataPutEmpreendimento,
  }
}


export function MutationPutEmpreendimentoChangeUser() {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    control,
  
    formState: {errors},
  } = useForm<FormEmpreendimentoUpdateFormData>({
    resolver: zodResolver(FormEmpreendimentoFormUpdateSchema),
  })

  const [setEmpreendimentoChangeUserBody, {error, loading, data: DataPutEmpreendimentoChangeUser}] =
    useMutation<TypesPutEmpreendimentosChangeUserResponse>(PUT_EMPREENDIMENTO_CHANGE_USER, {
      refetchQueries: [GET_EMPREENDIMENTO,GET_EMPREENDIMENTOS],
    })

  async function FormEmpreendimentoChangeUser({variables}: QueryEmpreendimentosProps) {

    console.log('data')
    console.log({
      variables:   { ...variables }
    
  })

   return  await setEmpreendimentoChangeUserBody({
      variables:   { ...variables }
    
    })
  }

  return {
    register,
    handleSubmit,
    FormEmpreendimentoChangeUser,
    loading,
    errors,
    error,
    control,
    setError,
    setValue,
    getValues,
    DataPutEmpreendimentoChangeUser,
  }
}
export function MutationDeleteEmpreendimentos() {
  const [deleteEmpreendimento, {loading, data, error}] = useMutation<DeleteEmpreendimentosResponse>(
    DELETE_EMPREENDIMENTO,
    {
      refetchQueries: [GET_EMPREENDIMENTOS, GET_EMPREENDIMENTO],
    }
  )

  async function HandleDeleteEmpreendimentos({variables}: QueryEmpreendimentosProps) {
    try {
      const result = await deleteEmpreendimento({
        variables: {...variables},
        refetchQueries: [GET_EMPREENDIMENTOS, GET_EMPREENDIMENTO],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return {HandleDeleteEmpreendimentos, loading, error, data}
}

export function MutationRecoveryEmpreendimento() {
  const [putEmpreendimento, {loading, data, error}] = useMutation<TypesPutEmpreendimentosResponse>(
    PUT_EMPREENDIMENTO,
    {
      refetchQueries: [GET_EMPREENDIMENTOS, GET_EMPREENDIMENTO],
    }
  )

  async function HandleRecoveryEmpreendimento({variables}: QueryEmpreendimentosProps) {
    try {
      const result = await putEmpreendimento({
        variables: {...variables},
        refetchQueries: [GET_EMPREENDIMENTOS, GET_EMPREENDIMENTO],
      })
      return result
    } catch (e) {
      if (e) {
        return false
      }
    }
  }

  return {HandleRecoveryEmpreendimento, loading, error, data}
}
