import {useState} from 'react'
import {Color, ColorResult} from 'react-color'
import Chrome from 'react-color/lib/components/chrome/Chrome'
import {HexToRgb, RgbToHex} from '../../pages/explorer/components/common'

type props = {
  defaultValue: string
  onChange: any
  register?: any
}

export const ColorPickerChorme = ({defaultValue, onChange, register}: props) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [color, setColor] = useState<Color>(null)

  const handleChange = (color: ColorResult) => {
    setColor({r: color.rgb.r, g: color.rgb.g, b: color.rgb.b, a: 100})
  }
  function handleClick() {
    setDisplayColorPicker(true)
  }

  let colorDefault = `rgba(200, 200, 200, 0)`
  if (defaultValue) {
    const defaultValueRgba: any = defaultValue ? HexToRgb(defaultValue) : null
    if (defaultValueRgba) {
      colorDefault = `rgba(${defaultValueRgba[0] + ''}, ${defaultValueRgba[1]}, ${
        defaultValueRgba[2]
      }, ${defaultValueRgba[3]})`
    }
  }

  const styles = {
    color: {
      width: '36px',
      height: '14px',
      borderRadius: '2px',
      background: color
        ? `rgba(${color['r'] + ''}, ${color['g']}, ${color['b']}, ${color['a']})`
        : colorDefault,
    },
    swatch: {
      padding: '5px',
      background: '#fff',
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
    },
    popover: {
      position: 'absolute',
      zIndex: '2',
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
  }

  return (
    <div className='p-2'>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
      </div>
      <input
        type='hidden'
        {...register}
        value={color ? RgbToHex(color['r'], color['g'], color['b'], color['a']) : defaultValue}
      ></input>
      {!displayColorPicker ? (
        <></>
      ) : (
        <div
          style={{
            position: 'relative',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
          }}
        >
          <Chrome
            color={color || defaultValue || '#333'}
            onChange={(color) => {
              handleChange(color)
              let cor = Object.values(color.rgb)
              cor.splice(3, 3)
              onChange(cor)
            }}
          />
        </div>
      )}
    </div>
  )
}
