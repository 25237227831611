import { gql } from "@apollo/client";

export const SET_EMPREENDIMENTO_SITUACAO = gql`
mutation SetEmpreendimentoSituacao($situacao: Situacao!) {
  SetEmpreendimentoSituacao(Situacao: $situacao) {
    id
  }
}
`

export const PUT_EMPREENDIMENTO_SITUACAO = gql`
mutation PutEmpreendimentoSituacao($putEmpreendimentoSituacaoId: Float!, $situacao: Situacao!) {
  PutEmpreendimentoSituacao(id: $putEmpreendimentoSituacaoId, Situacao: $situacao) {
    id
  }
}
`

export const DELETE_EMPREENDIMENTO_SITUACAO = gql`
mutation DeleteEmpreendimentoSituacao($deleteEmpreendimentoSituacaoId: Float!) {
  DeleteEmpreendimentoSituacao(id: $deleteEmpreendimentoSituacaoId) {
    id
  }
}
`