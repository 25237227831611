import {Text, View} from '@react-pdf/renderer'
import {styles} from '../style'

export const EmailsExtended = ({data, estrutura}) => {
  let dateReturn = null

  if (typeof data['Result'] !== 'undefined') {
    dateReturn = data['Result'][0]['ExtendedEmails']['Emails']
  } else {
    if (typeof data[0]['ExtendedEmails'] !== 'undefined'){
      dateReturn = data[0]['ExtendedEmails']['Emails']
    }
  }

  return dateReturn ? (
    <>
      <View>
        {dateReturn?.map((item, index) => {
          return (
            <View key={index} style={styles.row} wrap={false}>
              <Text style={[styles.space, styles.h4, styles.bold]}>{item['EmailAddress']}</Text>
            </View>
          )
        })}
      </View>
    </>
  ) : (
    <></>
  )
}
