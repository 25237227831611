import {useEffect, useState} from 'react'
import {Color} from 'react-color'
import Chrome from 'react-color/lib/components/chrome/Chrome'
import {HexToRgb} from '../../../explorer/components/common'

export const ColorPicker = ({hex, onChange}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [color, setColor] = useState<Color>({r: 0, g: 0, b: 0})
  
  useEffect(() => {
    if (hex) {
      const cor = HexToRgb(hex)
      setColor({r: cor[0], g: cor[1], b: cor[2], a: cor[3]})
    }
  }, [hex])

  const handleChange = (color) => {
    setColor({r: color.rgb.r, g: color.rgb.g, b: color.rgb.b, a: 100})
  }
  function handleClick() {
    setDisplayColorPicker(true)
  }

  // function handleClose(e) {
  //   e.stopPropagation()
  //   setDisplayColorPicker(false)
  // }

  const styles = {
    color: {
      width: '36px',
      height: '14px',
      borderRadius: '2px',
      background: `rgba(${color['r'] + ''}, ${color['g']}, ${color['b']}, ${color['a']})`,
    },
    swatch: {
      padding: '5px',
      background: '#fff',
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
    },
    popover: {
      position: 'absolute',
      zIndex: '2',
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
  }

  return (
    <div className='p-2'>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
      </div>
      {!displayColorPicker ? (
        <></>
      ) : (
        <div
          style={{
            position: 'relative',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
          }}
        >
          <Chrome
            color={color}
            onChangeComplete={(color) => {
              handleChange(color)
              let cor = Object.values(color.rgb)
              cor.splice(3, 3)
              onChange(cor)
            }}
          />
        </div>
      )}
    </div>
  )
}
