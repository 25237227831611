import { gql } from "@apollo/client";

export const SET_EQUIPE = gql`
mutation SetEquipe($equipe: equipe!) {
  SetEquipe(equipe: $equipe) {
    id
  }
}
`

export const PUT_EQUIPE = gql`
mutation PutEquipe($equipe: equipe!, $putEquipeId: Float!) {
  PutEquipe(equipe: $equipe, id: $putEquipeId) {
    id
  }
}
`

export const DELETE_EQUIPE = gql`
mutation DeleteEquipe($deleteEquipeId: Float!) {
  DeleteEquipe(id: $deleteEquipeId) {
    id
  }
}
`