import React, { useEffect, useState } from 'react'

import Select from 'react-select'
import { QueryGetCorretores } from '../../../../graphql/services/Corretores'
import { FormLabel } from 'react-bootstrap'
import { QueryGetEmpreendimentoSituacao } from '../../../../graphql/services/EmpreendimentoSituacao'
import { QueryGetEquipeByCargo } from '../../../../graphql'

export function GetUsuariosEquipesByCargoChosen({
  SetValue,
  value,
  showLabel=false
}) {
  let situacaoArray = []

  const [situacao, setSituacao] = useState('')

  useEffect(() => {
    setSituacao(value)
  }, [value])

  const { data } = QueryGetEquipeByCargo({
    variables: {
      pagination: {
        pagina: 0,
        quantidade: 100,
      }
    }
  })



  if (!data) {
    return (
      <>
        <div className='col-form-label'>
        {showLabel ? <FormLabel className='form-label'>Usuários</FormLabel> : ''}
          {/* <label className='col-form-label'>Corretores Disponiveis:</label> */}
          <Select
            options={situacaoArray}
            onChange={(e) => {
              setSituacao(e.value)
            }}
            value={situacaoArray.filter((e) => e.value)[0]}
            name='id_usuario'
            placeholder='Usuários da sua equipe'
          />
        </div>
      </>
    )
  }
  situacaoArray.push({ value:'', label: '--selecione--' })
  data.GetEmpresaUsuariosEquipesAdmin.result.map((Options) => {
    situacaoArray.push({ value: Options.id, label: Options.name })
    return []
  })



  return (
    <>
      <div className='form-group'>
      {showLabel ? <FormLabel className='form-label'>Usuários</FormLabel>:<></>}
        <Select
          value={situacaoArray.find((e) => e.value === situacao) || null}
          options={situacaoArray}
          onChange={(e) => {
            setSituacao(e.value)
            SetValue(e.value)
          }}
          name='id_usuario'
          placeholder='Usuários da sua equipe'
        />
      </div>
    </>
  )
}
