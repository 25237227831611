import {APiUrbit} from '../../../../../services/api/apiUrbit'
import {FormAVM} from '../context/AvmContext'

export function useAvm() {
  async function syncAvm(data) {
    const api = new APiUrbit()
    const result = await api.post(`avm`, data)
    return result
  }

  async function syncAvmGam({
    dados,
    finalidade = 'venda',
    dummies = 'sd',
  }: {
    dados: FormAVM
    finalidade: 'venda' | 'alguel'
    dummies: 'sd' | 'cd'
  }) {
    
    dados['finalidade'] = finalidade
    dados['dummies'] = dummies
    let result = null
    try {
      // `https://avm.urbit.com.br/predict?finalidade=${finalidade}&dummies=${dummies}`,
      const response = await fetch(`https://api.urbit.com.br/avm`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          // 'mode':'cors'
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1ZDBhODAxNDYwODVjMjA5Zjg1NTVjNWYiLCJyb2xlIjpbIlVzZXIiLCJBZG1pbiJdLCJpYXQiOjE2ODE0MDk0NjR9.PUObCxMX4TyDPyCesyytKG1wW849Dfnt_h_yPDbrRP8`,
          // 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1ZTBmZTA2NzkzMmVkODQyODY5OTZjYjMiLCJyb2xlIjpbIlVzZXIiLCJBZG1pbiJdLCJpYXQiOjE2Mjg2OTUwNjR9.qJmY1qf0ioySnj0S9QizKfCSmL3jPYemPGJgEni60Kc',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(dados),
      })
      result = await response.json()
    } catch (error) {
      result = {service: 'error', message: error, itens: [], total: 0}
      console.error('Error:', error)
    }

    // const api = new APiUrbit()
    // const result = await api.post(`avm-usados`, data)
    return result
  }

  return {syncAvm, syncAvmGam}
}
