// import Bar from "../../../components/Charts/AreaChart";
import { Header } from "../components/Header";
import { Property } from "./types";

type Props = {
    result: Property
    features: string
}

export function Infos({ result, features }: Props) {
    const featuresString = features
    console.log(featuresString)
    const featuresArray = Array.isArray(featuresString) ? featuresString : featuresString.split(',');

    const processedFeatures = featuresArray.map(feature => {
        return feature.trim();
    });
    
    return (
        <div>
            <div className="row">
                <div className="col-md-12 col-lg-12">
                  {/* <Bar ></Bar> */}
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 col-lg-12">
                    <Header className='card-xl-stretch' color='success' result={result} adress={result ? result[0].address : '0'} />
                </div>
            </div>

            <div className="row">
                <div className="property-summary">
                    <div className="row">
                        <div className="col-sm-6 bg-gray-400 py-6">
                            <div className="title-box-d">
                                <h2 className="title-d">Descrição</h2>
                            </div>
                            <hr />

                            <div className="text-justify ">
                                <div className="property-description">
                                    <p className="description fs-4">
                                        {result ? result[0].description : 'Carregando'}
                                    </p>
                                </div>
                            </div>


                        </div>
                        <div className="col-sm-6     p-6">
                            <div className="title-box-d">
                                <h2 className="title-d">Características</h2>
                            </div>
                            <hr />
                            <div className="amenities-list color-text-a">
                                <ul className="list-no no-margin" >
                                    <div className="all-center-warp w-100">
                                        {processedFeatures.map((feature, index) => (
                                            <div className='d-flex align-items-center mb-4 me-8' key={index}>

                                                {/* <span className='bullet bullet-vertical h-20px w-1px bg-success me-4'></span> */}

                                                <li className="fs-6 fw-bold text-gray-800">{feature}</li>
                                            </div>
                                        ))}
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}