import React from 'react'

import { Link } from 'react-router-dom'
import { FaHospitalUser, FaServer, FaCaretRight } from 'react-icons/fa'
import { MutationSetLogAcessoRecurso } from '../../../../../app/graphql/services/LogAcesso'
import { BiArrowBack, BiArrowFromRight, BiArrowToRight, BiBuilding, BiBuildings, BiRightArrow } from 'react-icons/bi'

export function MenuIM() {
  const { FormSetLogAcessoRecurso } = MutationSetLogAcessoRecurso()

  return (
    <>
      <span className='menu-link'>
        <span className='menu-icon'>
          <i className='ki-duotone ki-address-book fs-2'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
          </i>
        </span>
        <span className='menu-title'>Inteligência de mercado</span>
        <span className='menu-arrow'></span>
      </span>
      <div className='m-0'>
        <ul className='nav nav-pills nav-pills-custom flex-column border-transparent fs-5 fw-bold'>
          <li className='nav-item mt-5'>
            <Link
              to='/inteligencia-mercado'
              onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'inteligencia-mercado', funcionalidade: 'inteligencia-mercado', action: 'list', url: '/inteligencia-mercado' })}
              className='sub-menu-secondary nav-link text-muted text-active-primary text-hover-success p-0 border-0 '
            >
               <BiBuildings className='fs-1 me-1'></BiBuildings>
              
              Mercado Primário - Lançamentos
            </Link>
          </li>
          <li className='nav-item mt-2'>
            <Link
              to='/mercado-secundario/venda'
              onClick={() => FormSetLogAcessoRecurso({ ferramenta: 'inteligencia-mercado', funcionalidade: 'proprietarios', action: 'list', url: '/mercado-secundario/venda' })}
              className='sub-menu-secondary nav-link text-muted text-active-primary text-hover-success p-0 border-0 '
            >
             <BiBuilding className='fs-1 me-1'></BiBuilding>
              Mercado Secundário - Venda
            </Link>
          </li>

        </ul>
      </div>
    </>
  )
}
